@use 'abstracts/functions';
@use 'abstracts/variables';
@use 'abstracts/mixins/layout';
@use 'abstracts/mixins/base';
@use 'abstracts/mixins/typography';
@use 'abstracts/mixins/appearance';

$_break: functions.break(80);

.m-social {
  &__inner {
    @include layout.container-wide;
    @include base.fluid-scale(padding-top, functions.padding(x-large), 50px);
    @include base.fluid-scale(
      padding-bottom,
      functions.padding(x-x-large),
      50px
    );

    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__header {
    margin-right: functions.padding(x-small);
    margin-bottom: functions.padding(x-x-small);

    &--form {
      flex: 1 0 100%;
    }
  }

  &__signup-link {
    margin-right: functions.padding(x-x-small);
  }

  &__footer {
    @include base.fluid-scale(
      padding-top,
      functions.padding(small),
      functions.padding(x-x-small)
    );

    flex: 1 0 100%;
  }

  &__accounts {
    flex: 0 0 auto;
    margin-left: auto;

    @include base.respond($_break) {
      flex: 1 0 100%;
      margin-top: functions.padding(x-small);
      margin-left: 0;
    }
  }

  &__form-wrapper {
    flex: 1 0 auto;
  }

  &__form {
    display: flex;
    max-width: 640px;
    margin-right: functions.padding(medium);
    border-bottom: 1px solid var(--color-secondary);

    @include base.respond($_break) {
      margin-right: 0;
    }
  }

  &__input,
  &__submit {
    padding-top: functions.padding(small) * 0.5;
    padding-bottom: functions.padding(small) * 0.5;
  }

  &__input {
    @include appearance.input-unstyled;

    flex: 1 0 auto;
    max-width: 640px;

    &.focus-visible {
      outline: 0;
    }
  }

  &__submit {
    padding-right: functions.padding(x-x-small) * 0.5;
    padding-left: functions.padding(x-x-small) * 0.5;
    color: var(--color-secondary);
  }
}
