@use 'abstracts/functions';
@use 'abstracts/mixins/base';

.m-image-grid {
  display: flex;
  align-items: center;
  justify-content: center;

  &__figure {
    flex: 1 1 auto;
    max-width: 225px;

    & + & {
      @include base.fluid-scale(
        margin-left,
        functions.padding(small),
        functions.padding(x-x-small)
      );
    }
  }

  &__image {
    max-height: 225px;
    object-fit: contain;
  }
}
