@use 'abstracts/functions';
@use 'abstracts/variables';
@use 'abstracts/mixins/appearance';
@use 'abstracts/mixins/typography';
@use 'abstracts/mixins/base';

$_className: 'm-button';

.m-button {
  @include appearance.proto-button;
  @include typography.copy-button;

  padding: functions.padding(small) * 0.5 functions.padding(small);
  text-align: center;
  border: 1px solid transparent;
  border-radius: 26px;
  transition: background-color variables.$duration variables.$timing;

  &--block {
    display: inline-block;
    min-width: 245px;

    @include base.respond(functions.break(40)) {
      min-width: auto;
    }
  }

  .a-bg-white &,
  .a-bg-neutral10 & {
    @include appearance.bg-color(teal);

    &.#{$_className}--primary {
      @include appearance.bg-color(blue);
    }
  }

  .a-bg-ltblue &,
  .a-bg-blue &,
  .a-bg-teal & {
    @include appearance.bg-color(white);

    color: functions.palette(blue);
  }

  // Correct hover color for ltblue backgrounds
  .a-bg-ltblue & {
    color: var(--color-secondary);

    &:hover {
      color: var(--color);
      background-color: functions.dkpalette(ltblue);
    }
  }

  [class^='a-bg-'] & {
    &.#{$_className}--secondary {
      @include appearance.bg-color(white);

      color: var(--color-secondary);
      border: 1px solid var(--color-secondary);

      &:hover {
        background-color: var(--background-color-dark);
      }
    }
  }

  .a-bg-blue & {
    &.#{$_className}--secondary {
      @include appearance.bg-color(blue);

      color: functions.palette(white);
      border: 1px solid functions.palette(white);

      &:hover {
        background-color: var(--background-color-dark);
      }
    }
  }

  // Create a `.a-color-` class for each color in $palette map
  @each $name, $value in functions.token(colors) {
    .a-color-#{"" + $name} & {
      color: functions.palette($name);
    }
  }

  &:hover {
    background-color: var(--background-color-dark);
  }
}
