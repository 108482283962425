@use 'abstracts/functions';
@use 'abstracts/mixins/base';
@use 'abstracts/mixins/appearance';
@use 'abstracts/mixins/layout';

$_break: functions.break(60);

.m-404 {
  position: relative;
  display: flex;
  align-content: flex-start;
  align-items: flex-start;
  max-width: functions.container-width(max);
  margin: 0 auto;
  overflow: hidden;

  @include base.fluid-scale(
    padding,
    functions.padding(x-large),
    functions.padding(small)
  );

  @include base.respond($_break) {
    display: block;

    --padding: 0;
  }

  &::before {
    display: block;
    padding-bottom:
      calc(
        100% / (#{functions.image-width(hero)} / #{functions.image-height(hero)})
      );
    content: '';

    @include base.respond($_break) {
      display: none;
    }
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;

    @include base.respond($_break) {
      position: relative;
      height: 306px;
      object-position: left center;

      --padding: 0;
    }
  }

  &__inner {
    @include base.fluid-scale(
      padding,
      functions.padding(x-x-large),
      functions.padding(medium)
    );

    z-index: 1;
    flex: 0 1 auto;
    width: 70%;
    max-width: 587px;
    margin-left: auto;

    @include base.respond($_break) {
      width: 100%;
      max-width: none;
      margin: 0;
    }
  }

  &__header {
    @include base.fluid-scale(
      padding-bottom,
      functions.padding(large) - 10,
      functions.padding(small)
    );
  }

  &__search {
    @include base.fluid-scale(
      margin-top margin-bottom,
      functions.padding(large),
      functions.padding(small)
    );

    display: flex;
    color: var(--color-secondary);
    border-bottom: 1px solid var(--color-secondary);

    > * {
      padding-top: functions.padding(x-x-small);
      padding-bottom: functions.padding(x-x-small);
    }
  }

  &__search-input {
    @include appearance.input-unstyled;

    flex: 1 1 auto;
    margin-left: functions.padding(x-x-small);

    &.focus-visible {
      outline: 0;
    }

    &::-webkit-search-cancel-button {
      width: 18px;
      height: 18px;
      cursor: pointer;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25' width='18' height='18'%3E%3Cpath d='M13.8 12.2l9.9-10c.3-.3.3-.8 0-1.1-.3-.3-.8-.3-1.1 0l-9.9 10-9.9-10c-.3-.3-.8-.3-1.1 0-.3.3-.3.8 0 1.1l9.9 10L2 22c-.3.3-.3.8 0 1.1.1.1.3.2.5.2s.4-.1.5-.2l9.7-9.7 9.7 9.7c.1.1.3.2.5.2s.4-.1.5-.2c.3-.3.3-.8 0-1.1l-9.6-9.8z'/%3E%3C/svg%3E");
      appearance: none;
    }
  }
}
