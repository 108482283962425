@use 'abstracts/functions';
@use 'abstracts/variables';
@use 'abstracts/mixins/layout';
@use 'abstracts/mixins/base';
@use 'abstracts/mixins/appearance';

$_break: functions.break(60);

.m-search-results {
  --padding-top: 0;

  &__body {
    max-width: 933px;
    margin: 0 auto;
  }

  &__item {
    border-top: 1px solid var(--border-color);

    @include base.fluid-scale(
      padding-top padding-bottom,
      50px,
      functions.padding(small)
    );
  }

  &__link {
    display: grid;
    grid-template:
      'image title label' auto
      'image text label' 1fr
      / auto 1fr auto;

    @include base.fluid-scale(
      grid-column-gap,
      functions.padding(large),
      functions.padding(x-small)
    );

    grid-row-gap: functions.padding(x-x-small);

    @include base.respond(functions.break(80)) {
      grid-template:
        'image title label' auto
        'image text text' 1fr
        / auto 1fr auto;
    }

    @include base.respond(functions.break(60)) {
      grid-template:
        'image label' auto
        'image title' 1fr
        'text text' 1fr
        / auto 1fr auto;
    }

    @include base.respond(functions.break(40)) {
      display: block;

      > * + * {
        margin-top: functions.padding(x-x-small);
      }
    }
  }

  &__figure {
    grid-area: image;

    @include base.fluid-scale(width, 215px, 148px);

    @include base.respond(functions.break(40)) {
      --width: 100%;

      margin-bottom: functions.padding(x-x-small);
    }
  }

  &__img {
    transition: transform variables.$duration variables.$timing;

    .m-search-results__link:hover & {
      transform: scale(1.1);

      @include base.reduced-motion {
        opacity: 0.9;
        transform: none;
      }
    }
  }

  &__title {
    grid-area: title;
  }

  &__text {
    grid-area: text;
  }

  &__label {
    grid-area: label;
  }
}
