@use 'abstracts/functions';
@use 'abstracts/mixins/layout';
@use 'abstracts/mixins/base';
@use 'abstracts/mixins/typography';
@use 'abstracts/mixins/appearance';

$_wide-padding: functions.padding(large);
$_narrow-padding: functions.padding(small);
$_header-max-width: 950px;
$_height: functions.image-height(hero);

.m-hero-home {
  position: relative;
  width: 100%;
  max-width: functions.container-width(max);
  margin: 0 auto;
  overflow: hidden;

  &__img {
    @include base.fluid-scale(height, $_height * 1px, 304px);

    z-index: 0;
    width: 100%;
    object-fit: cover;

    @include base.respond(functions.break(60)) {
      max-height: 38vh;
    }
  }

  &__inner {
    @include base.fluid-scale(
      max-width,
      functions.container-width(wide) + 2 * $_wide-padding,
      functions.container-width(wide) + 2 * $_narrow-padding
    );
    @include base.fluid-scale(margin-top, -160px, -1px);

    position: relative;
    z-index: 1;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  &__header-wrapper {
    @include appearance.bg-color(blue);
    @include base.fluid-scale(padding-top, functions.padding(large), 26px);
    @include base.fluid-scale(padding-right, 70px, 40px);
    @include base.fluid-scale(padding-bottom, 70px, 10px);
    @include base.fluid-scale(
      padding-left,
      $_wide-padding,
      $_narrow-padding,
      functions.break(130),
      functions.break(60)
    );

    position: relative;
    z-index: 3;
    max-width: $_header-max-width;

    @include base.respond($_header-max-width) {
      @include appearance.bg-color(white);
    }

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 1px;
      z-index: -1;
      content: '';
      background: inherit;
      transform: translate(-100%, 0);
    }
  }

  &__header {
    @include base.fluid-scale(font-size, 37px, 25px);

    line-height: 1.25;

    @include base.respond(functions.break(60)) {
      line-height: 1.18;
    }

    > a {
      @include base.fluid-scale(background-position-y, 100%, 91%);
    }
  }

  &__header-ctas {
    display: none;
    margin-right: functions.padding(x-x-small) * -1;
    margin-left: functions.padding(x-x-small) * -1;

    @include base.fluid-scale(
      margin-top,
      functions.padding(small),
      functions.padding(x-small)
    );

    @include base.respond($_header-max-width) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    .m-button {
      display: block;
      flex: 1 1 45%;
      min-width: 200px;
      margin:
        0 functions.padding(x-x-small) functions.padding(x-x-small)
        functions.padding(x-x-small);
      white-space: nowrap;
    }
  }

  &__summary {
    @include base.fluid-scale(
      padding-top padding-bottom,
      $_wide-padding,
      functions.padding(medium)
    );
    @include base.fluid-scale(
      padding-left padding-right,
      $_wide-padding,
      $_narrow-padding
    );

    position: relative;
    z-index: 2;
    max-width: 470px;

    @include base.fluid-scale(margin-top, -25px, 0);

    margin-left: auto;
    text-align: left;

    @include base.fluid-scale(
      margin-right,
      functions.padding(large),
      functions.padding(small),
      functions.break(130),
      functions.break(60)
    );

    @include base.respond($_header-max-width) {
      max-width: none;
      margin-top: 0;
      margin-right: 0;
    }
  }
}
