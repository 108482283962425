@use 'abstracts/mixins/base';
@use 'abstracts/mixins/typography';
@use 'abstracts/functions';

.m-callout {
  // Extra padding for animated underline
  padding-bottom: 1px;

  &__figure {
    @include base.fluid-scale(
      margin-bottom,
      functions.padding(small) * 0.5,
      12px
    );

    position: relative;
  }

  &__figcaption {
    position: absolute;
    top: 20px;
    left: 22px;
  }

  &__link {
    @include typography.link-underlined;

    display: inline-block;
    margin-top: 26px;
  }

  &__label {
    @include typography.copy-label;
    @include base.fluid-scale(margin-top, 17px, functions.padding(x-x-small));
  }

  &__heading {
    @include typography.heading-quaternary;
  }

  &__text {
    @include typography.copy-small;
  }

  &__heading + &__text {
    margin-top: 5px;
  }
}
