@use 'sass:math';
@use 'abstracts/functions';
@use 'abstracts/mixins/layout';

$_className: 'm-video';

.#{$_className} {
  &__video-wrapper {
    position: relative;
    display: flex;

    &::after {
      display: block;
      padding-top: calc(var(--aspect-ratio, math.div(9, 16)) * 100%);
      content: '';
    }
  }

  &__video {
    flex: 1 0 100%;
    overflow: hidden;
  }

  &__embed {
    flex: 1 0 100%;
    width: 100%;
    height: 100%;
  }

  &__caption {
    margin-top: functions.padding(x-x-small);
    text-align: right;

    .#{$_className}--max & {
      @include layout.container-max;
    }
  }
}
