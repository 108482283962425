@use 'abstracts/mixins/base';
@use 'abstracts/mixins/appearance';
@use 'abstracts/functions';

$_padding-top: functions.padding(x-small);

.m-featured-patients {
  position: relative;

  --padding-top: #{$_padding-top};

  @include base.respond(functions.break(60)) {
    --padding-top: 0;
  }

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 0;
    display: block;
    height: $_padding-top + 120px;
    content: '';
    background: functions.palette(neutral10);

    .has-filters-open & {
      background: functions.palette(white);
    }
  }

  &__inner {
    position: relative;
    z-index: 1;
  }

  &__items {
    // Only show 3 items on mobile
    @include base.respond(functions.break(60)) {
      > *:nth-child(n + 4) {
        display: none;
      }
    }
  }
}
