@use '../abstracts/functions';
@use '../abstracts/mixins/appearance';
@use '../abstracts/mixins/base';

// Base
// --------------------------------------------------------

// Applies a natural box layout model to all the things
*,
*::before,
*::after {
  box-sizing: inherit;
}

// Global
// --------------------------
html {
  // Applies a natural box layout model to all the things
  box-sizing: border-box;

  // Set the base font size here, which will correspond to 1em inside <body>
  font-size: 100%;
  scroll-behavior: smooth;
}

body {
  // ensure page is always at least 100vh tall; `main` expands to fill space
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: functions.palette(neutral-80);
}

main {
  flex-grow: 1;
}

a {
  @include appearance.focus-default;

  color: inherit;
  text-decoration: none;
}

button {
  @include appearance.button-unstyled;
  @include appearance.focus-default;
}

svg:not([fill='none']) {
  fill: currentcolor;
}

legend {
  display: table;
  padding: 0;
}

fieldset {
  min-width: 0;
  padding: 0.01em 0 0;
  margin: 0;
  border: 0;
}

body:not(:-moz-handler-blocked) fieldset {
  display: table-cell;
}

input,
textarea,
select {
  @include appearance.input-unstyled;
}
