@use 'abstracts/functions';
@use 'abstracts/variables';
@use 'abstracts/mixins/base';

$_wide-padding: functions.padding(x-large);
$_narrow-padding: functions.padding(small);
$_break: functions.break(80);

.m-record-links {
  @include base.respond($_break) {
    margin-right: calc(var(--padding-right) * -1);
  }

  &__inner {
    @include base.fluid-scale(column-gap, $_wide-padding, $_narrow-padding);

    columns: 2;

    @include base.respond($_break) {
      columns: 1;

      &::after {
        display: none;
        content: 'flickity';
      }
    }
  }

  &__item {
    display: flex;
    align-items: flex-start;
    padding-top: functions.padding(x-small);
    padding-bottom: 25px;
    border-top: 1px solid var(--border-color);
    break-inside: avoid;

    @include base.respond($_break) {
      flex-direction: column;
      padding-top: 0;
      padding-bottom: 0;

      // Fixes issue with IE11 vars overriding border color
      --border-color: transparent;

      border-top: none;

      & + & {
        margin-left: functions.padding(grid-gap-mobile);
      }
    }
  }

  &__body,
  &__image-link {
    width: 270px;
  }

  &__body {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-items: flex-start;

    @include base.fluid-scale(
      padding-top padding-bottom,
      functions.padding(x-small),
      functions.padding(x-x-small)
    );
  }

  &__label {
    @include base.fluid-scale(
      margin-bottom,
      functions.padding(x-x-small),
      functions.padding(x-x-small) * 0.5
    );
  }

  &__title {
    max-width: 100%; // IE11
    padding-bottom: functions.padding(x-x-small);
  }

  &__text {
    flex: 1 1 auto;
    max-width: 100%; // IE11
    padding-bottom: functions.padding(x-small);
  }

  &__link {
    font-size: 16px;
    font-weight: functions.font-weight(medium);
  }

  &__image-link {
    max-width: 216px;
    margin-right: 25px;

    @include base.respond($_break) {
      max-width: 100%;
      margin-right: 0;
    }
  }
}
