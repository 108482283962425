@use 'abstracts/functions';
@use 'abstracts/mixins/base';

.m-image-column {
  &__body {
    display: grid;
    grid-template:
      'text image' auto
      / 1fr 1fr;

    @include base.respond(functions.break(60)) {
      grid-template:
        'text' auto
        'image' auto
        / 1fr;
    }
  }

  &__text-wrapper {
    grid-area: text;
  }

  &__image-wrapper {
    grid-area: image;
    align-self: center;
  }

  &__image {
    width: 100%;
    max-width: var(--max-width);
    height: auto;
    margin:
      calc(var(--padding-top) * -1) calc(var(--padding-right) * -1)
      calc(var(--padding-bottom) * -1) auto;

    @include base.respond(functions.break(60)) {
      max-width: none;
      margin: var(--padding-top) 0 0 0;
    }
  }
}
