@use 'abstracts/functions';
@use 'abstracts/mixins/typography';
@use 'abstracts/mixins/base';

.m-rte {
  > * + * {
    @include base.fluid-scale(
      margin-top,
      functions.padding(small),
      functions.padding(x-small)
    );
  }

  ul:not([class]) {
    @include typography.list-unordered-primary;
  }

  ol:not([class]) {
    @include typography.list-ordered-primary;
  }

  .m-button {
    margin-right: 1em;

    + .m-button {
      margin-top: 1em;
    }
  }
}
