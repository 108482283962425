@use 'abstracts/mixins/layout';
@use 'abstracts/mixins/typography';
@use 'abstracts/functions';

.m-footer-nav {
  @include layout.grid(
    2,
    functions.padding(medium),
    functions.padding(x-small)
  );

  &__header {
    @include typography.heading-senary;

    margin-bottom: functions.padding(x-x-small);
  }

  &__item {
    margin-bottom: functions.padding(x-x-small) * 0.5;
  }

  &__link {
    @include typography.link-navigation;

    color: var(--color-secondary);
  }
}
