@use 'abstracts/functions';
@use 'abstracts/mixins/base';
@use 'abstracts/mixins/typography';

.m-record-results {
  &__item {
    display: flex;
    padding-top: 25px;
    border-top: 1px solid var(--border-color);

    @include base.respond(functions.break(60)) {
      margin-bottom: functions.padding(x-x-small);
    }

    @include base.respond(functions.break(30)) {
      flex-wrap: wrap;
    }
  }

  &__figure {
    position: relative;
    flex: 0 0 auto;
    margin-right: 25px;
  }

  &__figcaption {
    position: absolute;
    right: 0;
    bottom: functions.padding(x-x-small);
  }

  &__image-link {
    @include base.fluid-scale(width, 216px, 148px);
  }

  &__img {
    object-fit: cover;
  }

  &__body {
    flex: 1 1 auto;

    @include base.respond(functions.break(30)) {
      margin-top: functions.padding(x-x-small);
    }
  }

  &__title {
    max-width: 100%; // Fixes IE11 bug where text does not wrap
    padding-bottom: functions.padding(x-x-small);
  }

  &__text {
    max-width: 100%; // Fixes IE11 bug where text does not wrap
    padding-bottom: functions.padding(x-small);
  }

  &__date {
    display: inline-block;
    padding-top: functions.padding(x-x-small);

    @include typography.copy-caption;

    text-align: left;
  }

  &__label:first-child {
    padding-bottom: functions.padding(x-x-small);
  }
}
