@use 'abstracts/functions';
@use 'abstracts/mixins/base';
@use 'abstracts/mixins/layout';
@use 'abstracts/mixins/appearance';

$_break: functions.break(60);

.m-table {
  display: table;
  width: 100%;
  border-collapse: collapse;

  @include base.respond($_break) {
    display: block;

    &__head {
      @include appearance.hidden;
    }

    &__body,
    &__row,
    &__cell {
      display: block;
    }
  }

  &__header {
    text-align: left;

    @include base.fluid-scale(margin-bottom, 50px, 25px);

    @include base.respond(functions.break(100)) {
      padding-right: functions.padding(x-small);
    }

    @include base.respond($_break) {
      display: block;
    }
  }

  &__head &__cell {
    white-space: nowrap;
  }

  &__row {
    @include base.respond($_break) {
      padding-bottom: functions.padding(large);
    }
  }

  &__cell {
    padding:
      functions.padding(x-x-small) functions.padding(x-small)
      functions.padding(x-x-small) 0;
    text-align: left;
    border-bottom: 1px solid var(--border-color);

    @include base.respond($_break + 1, 'min') {
      padding-left: functions.padding(x-x-small);

      &:first-child {
        padding-left: 0;
      }

      &--right {
        text-align: right;
      }
    }

    @include base.respond($_break) {
      &::before {
        padding-right: functions.padding(x-x-small);
        font-weight: bold;
        content: attr(data-title);
      }
    }
  }
}
