@use 'abstracts/mixins/appearance';
@use 'abstracts/mixins/base';
@use 'abstracts/variables';
@use 'abstracts/functions';

/*! Flickity v2.2.1
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  @include appearance.focus-default;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:focus,
  &:focus-visible {
    outline: 0;
  }

  &:focus-visible::after {
    display: block;
    content: "•";
    position: absolute;
    top: 0.125em;
    left: 0.5em;
    font-size: 2em;
    color: var(--color-secondary);
  }
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
  transition: height 0.25s ease-in-out;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- page dots ---- */

ol.flickity-page-dots {
  @include base.fluid-scale(padding-top, 23px, 19px);
  width: 100%;
  margin: 0;
  list-style: none;
  line-height: 1;
  display: flex;
  max-width: functions.container-width(wide);
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
  text-align: right;
}

.flickity-page-dots .dot {
  @include base.fluid-scale(padding-top, 23px, 19px);
  flex: 1 1 auto;
  height: 0;
  margin: 0;
  border-bottom: 1px solid var(--border-color);
  cursor: pointer;
  transition: border variables.$duration variables.$timing;
}

.flickity-page-dots .dot.is-selected {
  border-bottom-color: var(--color-secondary);
}

/* ---- flickity-button ---- */

// .flickity-button {
//   position: absolute;
//   background: hsla(0, 0%, 100%, 0.75);
//   border: none;
//   color: #333;
// }
//
// .flickity-button:hover {
//   background: white;
//   cursor: pointer;
// }
//
// .flickity-button:focus {
//   outline: none;
//   box-shadow: 0 0 0 5px #19F;
// }
//
// .flickity-button:active {
//   opacity: 0.6;
// }
//
// .flickity-button:disabled {
//   opacity: 0.3;
//   cursor: auto;
//   /* prevent disabled button from capturing pointer up event. #716 */
//   pointer-events: none;
// }
//
// .flickity-button-icon {
//   fill: currentColor;
// }

/* ---- previous/next buttons ---- */

// .flickity-prev-next-button {
//   top: 50%;
//   width: 44px;
//   height: 44px;
//   border-radius: 50%;
//   /* vertically center */
//   transform: translateY(-50%);
// }
//
// .flickity-prev-next-button.previous { left: 10px; }
// .flickity-prev-next-button.next { right: 10px; }
// /* right to left */
// .flickity-rtl .flickity-prev-next-button.previous {
//   left: auto;
//   right: 10px;
// }
// .flickity-rtl .flickity-prev-next-button.next {
//   right: auto;
//   left: 10px;
// }
//
// .flickity-prev-next-button .flickity-button-icon {
//   position: absolute;
//   left: 20%;
//   top: 20%;
//   width: 60%;
//   height: 60%;
// }
