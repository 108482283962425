@use 'abstracts/functions';
@use 'abstracts/mixins/base';

$_className: 'm-reportanimal';
$_break: functions.break(100);

.#{$_className} {
  &__header {
    @include base.fluid-scale(
      padding-bottom,
      functions.padding(medium),
      functions.padding(small)
    );
  }

  &__columns {
    @include base.fluid-scale(
      --grid-padding,
      functions.padding(small),
      functions.padding(x-small)
    );

    @include base.respond($_break) {
      flex-direction: column;
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__form {
    min-width: 545px;

    @include base.respond($_break) {
      min-width: auto;
      margin-bottom: functions.padding(medium);
    }
  }

  &__loading:not([hidden]) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: functions.padding(large) 0;
  }

  &__examples {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
  }

  &__example {
    position: relative;
    margin: 0;
    margin-right: -1px;
    margin-bottom: -1px;
    list-style: none;
    border: 1px solid var(--border-color);

    &:nth-child(1),
    &:nth-child(2) {
      flex: 1 0 100%;
      padding-top: 30px;
    }

    &:nth-child(3),
    &:nth-child(4) {
      flex: 1 0 50%;
      padding-top: 60px;
    }
  }

  &__example-text {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    padding: 26px 30px;

    > * + * {
      margin-top: 5px;
    }
  }

  &__example-svg {
    width: 100%;
    margin: 0 auto;
    color: var(--color-secondary);
  }

  &__upload {
    @include base.respond(functions.break(60)) {
      margin: 0 0 functions.padding(large) 0;
    }
  }
}
