@use '../abstracts/variables';
@use '../abstracts/functions';
@use '../abstracts/mixins/typography';

// Typography
// --------------------------------------------------------

body {
  font-family: variables.$font-stack-base;
  font-size: 1em;
  line-height: variables.$line-height-base;
}

// Block-level elements
// --------------------------
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1 {
  @include typography.heading-primary;
}

h2 {
  @include typography.heading-secondary;
}

h3 {
  @include typography.heading-tertiary;
}

h4 {
  @include typography.heading-quaternary;
}

h5 {
  @include typography.heading-quinary;
}

h6 {
  @include typography.heading-senary;
}

p {
  @include typography.proto-copy;

  margin: 0;
}

// Inline elements
// --------------------------

// Unstyled links or October file links
a:not([class]),
a[class='fr-file'] {
  @include typography.link-underlined;
}

button:not([class]) {
  @include typography.copy-button;
}

blockquote:not([class]) {
  @include typography.quote-primary;
}
