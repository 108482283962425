@use 'abstracts/mixins/appearance';
@use 'abstracts/mixins/base';
@use 'abstracts/mixins/layout';
@use 'abstracts/functions';
@use 'abstracts/variables';

.m-featured-publications {
  &__grid {
    @include layout.grid(
      3,
      functions.padding(grid-gap-desktop),
      functions.padding(small)
    );

    @include base.respond(functions.break(100)) {
      @include layout.grid(
        2,
        functions.padding(grid-gap-desktop),
        functions.padding(small)
      );
    }
  }

  &__item {
    transition: background-color variables.$duration variables.$timing;

    &:hover {
      background-color: var(--background-color-dark);
    }
  }

  &__text {
    display: block;
    padding: functions.padding(medium);
  }

  &__label {
    margin-bottom: functions.padding(x-small);
  }

  &__footer {
    @include base.fluid-scale(margin-top, 70px, functions.padding(medium));
  }
}
