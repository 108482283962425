@use 'abstracts/functions';
@use 'abstracts/mixins/layout';
@use 'abstracts/mixins/base';
@use 'abstracts/mixins/appearance';

$_duration: 400ms;
$_visibility-delay: $_duration * 0.5;
$_gap: functions.padding(grid-gap-desktop);
$_gap-mobile: functions.padding(grid-gap-mobile);

.m-grid {
  &__items {
    @include layout.grid(3, $_gap, $_gap-mobile);
  }

  &--2 &__items {
    @include layout.grid(2, 90px, functions.padding(x-x-small));
  }

  &--4 &__items {
    @include layout.grid(4, $_gap, $_gap-mobile);
  }

  &__see-more {
    display: block;
    margin-right: auto;
    margin-left: auto;

    @include base.fluid-scale(margin-top, $_gap, $_gap-mobile);

    &[aria-expanded='true'] {
      position: absolute;
      right: 0;
      left: 0;

      @include appearance.fade-out;
    }
  }

  &__extra {
    @include appearance.read-more;
    @include base.fluid-scale(margin-top, $_gap, $_gap-mobile);
  }
}
