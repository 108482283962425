@use 'abstracts/functions';
@use 'abstracts/mixins/base';

$_gap: functions.padding(grid-gap-desktop);

.m-membership-levels {
  margin-left: -$_gap;

  @include base.fluid-scale(
    padding-bottom,
    functions.padding(x-x-large) * -1,
    functions.padding(medium) * -1
  );

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__item {
    display: flex;
    flex: 1 1 calc(33% - #{$_gap});
    flex-direction: column;
    max-width: 370px;
    margin-left: $_gap;
    border-top: 1px solid var(--border-color);

    @include base.fluid-scale(
      padding-bottom,
      functions.padding(x-x-large),
      functions.padding(medium)
    );

    @include base.respond(functions.break(110)) {
      flex: 1 1 calc(50% - #{$_gap});
    }

    @include base.respond(functions.break(60)) {
      flex: 1 1 100%;
    }
  }

  &__link {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__name {
    padding: functions.padding(small) 0 functions.padding(small) + 3px 0;
  }

  &__image {
    height: auto;
  }

  &__amount {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-end;
    padding:
      functions.padding(x-small) - 5px functions.padding(small)
      functions.padding(x-small);
  }
}
