@use 'abstracts/mixins/base';
@use 'abstracts/mixins/layout';
@use 'abstracts/functions';

$_block-class: 'm-content-block';

.#{$_block-class} {
  &--max {
    max-width: functions.container-width(max);
    margin: 0 auto;
  }

  &--block {
    @include base.fluid-scale(
      padding-top padding-bottom,
      functions.padding(x-x-large),
      functions.padding(medium)
    );

    // If two colors are next to each other, remove the top padding
    @each $name, $value in functions.token(colors) {
      &.a-bg-#{"" + $name} + &.a-bg-#{"" + $name} {
        padding-top: 0;
      }
    }
  }

  &--inline {
    & + & {
      @include base.fluid-scale(
        margin-top,
        functions.padding(x-x-large),
        functions.padding(medium)
      );
    }
  }

  &__header {
    @include base.fluid-scale(margin-bottom, 50px, 25px);
  }

  &__label {
    flex: 0 0 100%;

    @include base.fluid-scale(
      margin-bottom,
      functions.padding(small),
      functions.padding(x-small)
    );
  }

  &__heading-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include base.respond(functions.break(60)) {
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
    }
  }

  &__heading {
    flex: 1 1 auto;
    padding-right: functions.padding(x-small);

    @include base.respond(functions.break(60)) {
      max-width: none;
    }
  }

  &__button {
    flex: 0 0 auto;

    @include base.respond(functions.break(60)) {
      margin-top: functions.padding(x-small);
      text-align: center;

      .m-button {
        width: 100%;
      }
    }
  }

  &__header-text {
    @include base.fluid-scale(
      margin-top,
      functions.padding(small),
      functions.padding(x-small)
    );
  }

  &__inner {
    // Default container wide, otherwise use l-container class
    &:not([class*='l-container']) {
      .#{$_block-class}--block > & {
        @include layout.container-wide;
      }
    }
  }

  &__body + * {
    @include base.fluid-scale(margin-top, 50px, 25px);
  }

  &.reduce-space-top {
    margin-top: 20px;
  }
}
