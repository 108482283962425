@use '../functions';
@use './base' as base-mixins;
@use '../variables';
@use 'sass:string';

// Typography mixins
// -----------------------------------
// These should be limited to `font` and other typographic properties only.

// Headings
// --------------------------------------------------------
@mixin proto-heading {
  font-family: variables.$font-stack-heading;
  font-weight: functions.font-weight(medium);
}

@mixin heading-primary($fontSize: 37px) {
  @include proto-heading;
  @include base-mixins.fluid-scale(font-size, $fontSize, 25px);
  @include base-mixins.fluid-scale(letter-spacing, 0, -0.21px);

  line-height: 1.12;
}

@mixin heading-secondary {
  @include proto-heading;
  @include base-mixins.fluid-scale(font-size, 33px, 25px);

  line-height: 1.3;
  letter-spacing: 0;
}

@mixin heading-tertiary {
  @include proto-heading;
  @include base-mixins.fluid-scale(font-size, 26px, 22px);

  line-height: 1.143;
  letter-spacing: 0;
}

@mixin heading-quaternary {
  @include proto-heading;
  @include base-mixins.fluid-scale(font-size, 22px, 19px);

  line-height: 1.23;
  letter-spacing: -0.16px;
}

@mixin heading-quinary {
  @include proto-heading;
  @include base-mixins.fluid-scale(font-size, 20px, 19px);

  line-height: 1.35;
  letter-spacing: -0.21px;
}

@mixin heading-senary {
  @include proto-heading;

  font-size: 19px;
  font-weight: functions.font-weight(semibold);
  line-height: 1.21;
  letter-spacing: 0;
}

// Copy
// --------------------------------------------------------

@mixin proto-copy {
  line-height: 1.412;
  letter-spacing: 0;
}

@mixin proto-all-caps {
  font-weight: functions.font-weight(semibold);
  text-transform: uppercase;
  letter-spacing: 0.47px;
}

@mixin copy-primary {
  @include proto-copy;

  font-family: variables.$font-stack-base;
  font-size: variables.$font-size-base;
  font-weight: functions.font-weight(regular);
}

@mixin copy-small {
  @include copy-primary;

  font-size: 16px;
}

@mixin copy-bold {
  @include proto-copy;

  font-weight: functions.font-weight(bold);
}

@mixin copy-label {
  @include proto-heading;
  @include proto-all-caps;

  font-size: 14px;
  line-height: 1.285;
  color: var(--color-light);

  .a-bg-blue & {
    color: var(--color);
  }

  &.a-tag {
    color: functions.palette(blue);
  }
}

@mixin copy-button {
  @include proto-heading;
  @include proto-all-caps;

  line-height: 1.2;
}

@mixin copy-caption {
  @include copy-primary;

  font-size: 13px;
  color: var(--color-light);
  text-align: right;

  @include base-mixins.respond(functions.break(60)) {
    text-align: left;
  }
}

@mixin copy-x-large {
  @include base-mixins.fluid-scale(font-size, 100px, 80px);

  margin-top: 0;
  font-family: variables.$font-stack-heading;
  font-weight: functions.font-weight(medium);
}

@mixin copy-x-x-large {
  @include base-mixins.fluid-scale(font-size, 150px, 100px);

  margin-top: 0;
  font-family: variables.$font-stack-heading;
  font-weight: functions.font-weight(medium);
}

// Links
// --------------------------------------------------------
@mixin link {
  font-weight: inherit;
  color: var(--color-secondary);
  cursor: pointer;
  border-bottom: 1px solid transparent;
  transition: color variables.$duration variables.$timing;

  &:hover {
    color: var(--color-secondary-dk);

    @include underline-animation;
  }
}

@mixin link-underlined {
  @include link;
  @include underline;
}

@mixin link-navigation {
  @include base-mixins.fluid-scale(font-size, 18px, variables.$font-size-base);

  font-family: variables.$font-stack-heading;
  font-weight: functions.font-weight(medium);
  background-size: 0% 1px;
  transition: color variables.$duration variables.$timing;

  &:hover {
    @include underline-animation;
  }
}

@mixin link-mobilenav {
  @include link-navigation;

  font-size: 19px;
}

// Lists
// --------------------------------------------------------
@mixin list-unstyled {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

@mixin list-unordered-primary {
  @include proto-copy;

  padding: 0;
  margin-bottom: 0;

  &:first-child {
    margin-top: 0;
  }

  > li {
    padding-bottom: 1em;
    break-inside: avoid;

    &:not([class]) {
      padding-inline-start: 6px;
      list-style-position: inside;
    }
  }
}

@mixin list-ordered-primary {
  @include list-unordered-primary;
}

@mixin list-item-checkmark {
  position: relative;
  padding-left: functions.padding(small);
  list-style: none;

  &::before {
    position: absolute;
    left: 0;
    display: block;
    margin-inline-end: functions.padding(x-small);
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 10' width='14' height='10'%3E%3Cpath d='M13.9.6c-.1-.4-.5-.6-.9-.6-.2 0-.5.1-.7.3C10 2.7 7.4 5.4 5 7.8L1.6 5c-.2-.2-.4-.3-.7-.3-.3 0-.5.1-.7.3-.2.3-.2.5-.2.8 0 .3.2.5.4.7l4.1 3.3c.4.3.9.3 1.3-.1 2.6-2.6 5.4-5.6 7.9-8.1.3-.2.4-.7.2-1z' fill='#{functions.encode-color(functions.palette(blue))}' fill-rule='evenodd' clip-rule='evenodd'/%3E%3C/svg%3E%0A");
  }
}

@mixin list-unordered-checkmark {
  @include list-unordered-primary;

  margin-left: 0;

  > li {
    @include list-item-checkmark;
  }
}

// Editor styles
// --------------------------------------------------------
@mixin left-border {
  padding-inline-start: functions.padding(x-small);
  border-left: 1px solid var(--border-color);
  break-inside: avoid;
}

// Quotes
// --------------------------------------------------------
@mixin quote-primary {
  @include heading-secondary;

  font-style: italic;

  &::before {
    margin-bottom: functions.padding(x-x-small);
    content: open-quote;
  }

  &::after {
    margin-top: functions.padding(small);
    content: close-quote;
  }

  &::before,
  &::after {
    display: block;
    font-style: normal;
    color: var(--color-secondary);
    text-align: center;
  }
}

// Underline animation
@mixin underline {
  // IE11 fallback. IE11 does support linear-gradient, just not very well
  border-bottom: 1px solid var(--color-secondary);

  // A linear-gradient is needed for a multi-line underline animation
  @supports (background-image: linear-gradient(#fff, #fff)) {
    background-image:
      linear-gradient(
        var(--color-secondary-dk),
        var(--color-secondary-dk)
      );
    background-repeat: no-repeat;
    background-position: 0 100%;
    background-size: 100% 1px;
    border-bottom: none;
  }
}

@mixin underline-animation {
  @include underline;

  border-bottom: 1px solid var(--color-secondary-dk);

  @supports (background-image: linear-gradient(#fff, #fff)) {
    border-bottom: none;
    animation-name: underline-keyframes;
    animation-duration: 0.3s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
  }
}

@keyframes underline-keyframes {
  0% {
    background-size: 0% 1px;
  }

  100% {
    background-size: 100% 1px;
  }
}
