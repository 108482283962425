@use '../functions';
@use '../variables';
@use 'sass:color';
@use 'sass:map';

// Appearance mixins
// --------------------
// These should be limited to appearance-related properties only.

@mixin focus-default {
  .js-focus-visible &:focus:not(.focus-visible) {
    outline: 0;
    outline-width: 0;
  }

  &.focus-visible {
    outline: auto 4px;
  }
}

// Icons
// --------------------------------------------------------
@mixin proto-icon {
  display: inline;
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: currentcolor;
}

// Links, Buttons
// --------------------------------------------------------
@mixin button-unstyled {
  padding: 0;
  font: inherit;
  color: inherit;
  cursor: pointer;
  background: transparent;
  border: 0;
  appearance: none;
}

// To be combined with a-bg-<color> classes
@mixin proto-button {
  @include button-unstyled;
  @include focus-default;

  box-sizing: border-box;
  display: inline-block;
  cursor: pointer;
}

// Hidden elements for screen readers
// --------------------------------------------------------
@mixin hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

// Inputs
// --------------------------------------------------------
@mixin input-unstyled {
  @include focus-default;

  display: inline-block;
  font-size: inherit;
  color: inherit;
  background: transparent;
  border: 0;
  appearance: none;
}

// Color Variables
// --------------------------------------------------------
@mixin bg-color($name) {
  $value: functions.palette($name);
  $_color: if(
    color.lightness($value) > 50%,
    functions.palette(black),
    functions.palette(white)
  );
  $_color-secondary: if(
    color.lightness($value) > 50%,
    functions.palette(blue),
    functions.palette(white)
  );
  $_color-secondary-dk: if(
    color.lightness($value) > 50%,
    functions.dkpalette(blue),
    functions.dkpalette(white)
  );
  $_border-color: if(
    color.lightness($value) > 90%,
    functions.dkpalette(neutral10),
    functions.palette(white)
  );
  $_color-light: if(
    color.lightness($value) > 50%,
    color.adjust($_color, $alpha: -0.3),
    color.adjust($_color, $alpha: -0.2)
  );

  color: $_color;
  background-color: $value;

  --background-color: #{$value};
  --color: #{$_color};
  --color-secondary: #{$_color-secondary};
  --color-secondary-dk: #{$_color-secondary-dk};
  --border-color: #{$_border-color};
  --color-light: #{$_color-light};
  --background-color-dark: #{functions.dkpalette($name)};
}

// Animations
// --------------------------------------------------------
$_duration: 400ms;
$_visibility-delay: $_duration * 0.5;

@mixin fade-out() {
  pointer-events: none;
  animation-name: fade-out;
  animation-duration: $_duration;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  99% {
    opacity: 0;
  }

  100% {
    overflow: hidden;
    opacity: 0;
  }
}

@mixin read-more() {
  transition:
    opacity #{$_duration} $_visibility-delay ease-in-out,
    max-height #{$_duration} $_visibility-delay ease-in;

  &[aria-hidden='true'] {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
  }

  &[aria-hidden='false'] {
    max-height: var(--readmore-area-height);
    overflow: hidden;
    opacity: 1;
  }
}
