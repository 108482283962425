@use 'abstracts/functions';
@use 'abstracts/mixins/base';

.m-meet-our-patient {
  &__links {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > * {
      margin-right: functions.padding(small);
      margin-bottom: functions.padding(x-small);
    }
  }
}
