@use 'abstracts/functions';
@use 'abstracts/mixins/layout';
@use 'abstracts/mixins/base';

.m-icon-grid {
  &__body {
    @include layout.grid(2);
  }

  &__item {
    @include base.fluid-scale(
      padding-top,
      functions.padding(medium),
      functions.padding(small)
    );

    border-top: 1px solid var(--border-color);
  }

  &__item-body {
    display: flex;
  }

  &__item-text {
    @include base.fluid-scale(
      margin-top,
      functions.padding(x-small),
      functions.padding(x-x-small)
    );

    flex: 1 1 auto;
  }

  &__icon {
    @include base.fluid-scale(margin-left, functions.padding(medium), 30px);

    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    color: var(--color-secondary);
  }

  &__svg {
    margin-inline: auto;
  }
}
