@use 'abstracts/mixins/layout';
@use 'abstracts/mixins/appearance';
@use 'abstracts/mixins/base';
@use 'abstracts/mixins/typography';
@use 'abstracts/functions';
@use 'abstracts/variables';

.m-search {
  @include appearance.bg-color(neutral10);

  position: absolute;
  top: -40%;
  right: 100%;
  left: 0;
  height: var(--site-nav-primary-height, 62px);
  overflow: hidden;
  transition: left 0.3s ease-out, opacity variables.$duration ease-out;

  @include base.respond(functions.break('mobilenav')) {
    @include layout.container-x-wide;

    top: calc(var(--site-header-height) - 1px);
    right: 0;
    left: 0;
    height: calc(100vh - var(--site-header-height) + 1px);
    padding-bottom: 25px;
    overflow-y: auto;
  }

  &[aria-hidden='true'] {
    @include base.respond(functions.break('mobilenav')) {
      display: none;
    }
  }

  &[aria-hidden='false'] {
    z-index: functions.z-stack(mobilenav);

    @include base.respond(functions.break('mobilenav'), 'min') {
      left: calc(-1 * var(--site-nav-primary-width, 60vw));
    }
  }

  &__form {
    display: flex;
    align-items: center;
    justify-content: center;

    @include base.respond(functions.break('mobilenav'), 'min') {
      height: 100%;
      margin-right: functions.padding(x-small);
      margin-left: functions.padding(x-small);
    }
  }

  &__input {
    @include appearance.input-unstyled;
    @include base.fluid-scale(font-size, 22px, 19px);

    flex: 1 1 auto;
    padding-top: functions.padding(x-x-small);
    padding-bottom: functions.padding(x-x-small);
    font-family: variables.$font-stack-heading;

    &.focus-visible {
      outline: 0;
    }

    &::-webkit-search-cancel-button {
      width: 18px;
      height: 18px;
      cursor: pointer;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25' width='18' height='18'%3E%3Cpath d='M13.8 12.2l9.9-10c.3-.3.3-.8 0-1.1-.3-.3-.8-.3-1.1 0l-9.9 10-9.9-10c-.3-.3-.8-.3-1.1 0-.3.3-.3.8 0 1.1l9.9 10L2 22c-.3.3-.3.8 0 1.1.1.1.3.2.5.2s.4-.1.5-.2l9.7-9.7 9.7 9.7c.1.1.3.2.5.2s.4-.1.5-.2c.3-.3.3-.8 0-1.1l-9.6-9.8z'/%3E%3C/svg%3E");
      appearance: none;
    }
  }

  &__submit {
    @include base.fluid-scale(
      padding,
      functions.padding(x-small),
      functions.padding(x-x-small)
    );
  }

  &__arrow {
    width: 20px;
    height: 20px;
    color: var(--color-light);
  }
}
