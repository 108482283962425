@use 'sass:math';
@use '../functions';
@use './base' as base-mixins;
@use '../variables';

// Layout mixins
// -------------------

// Containers
// --------------------------------------------------------
@mixin proto-container(
  $max-width: functions.container-width(wide),
  $wide-padding: functions.padding(large),
  $narrow-padding: functions.padding(small)
) {
  @include base-mixins.fluid-scale(
    max-width,
    $max-width + 2 * $wide-padding,
    $max-width + 2 * $narrow-padding
  );
  @include base-mixins.fluid-scale(
    padding-right padding-left,
    $wide-padding,
    $narrow-padding
  );

  margin-right: auto;
  margin-left: auto;
}

// Wide Container
@mixin container-wide {
  @include proto-container;
}

// The highest-level container for page content. Sets a max-width and centers its children.
@mixin container-max {
  @include proto-container(functions.container-width(max));
}

@mixin container-x-wide {
  @include proto-container(functions.container-width(x-wide));
}

@mixin container-narrow {
  @include proto-container(functions.container-width(narrow));
}

@mixin grid(
  $columns: 2,
  $gap-desktop: functions.padding(x-large),
  $gap-mobile: functions.padding(small),
  $break: functions.break(60)
) {
  $supports-query: '(grid-auto-columns: min-content)';

  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  // Use CSS Grid if supported
  // ----
  // Autoprefixer does not support autoplacement (mostly)
  // so test for a newer grid spec property to limit implementation,
  // with Flexbox as fallback
  // see https://github.com/postcss/autoprefixer#does-autoprefixer-polyfill-grid-layout-for-ie

  @supports (#{$supports-query}) {
    /* autoprefixer: off */
    display: grid;
    grid-template-columns: repeat($columns, 1fr);

    @if $gap-mobile != $gap-desktop {
      @include base-mixins.fluid-scale(gap, $gap-desktop, $gap-mobile);
    } @else {
      gap: $gap-desktop;
    }
  }

  > * {
    flex: 0 1 auto;
    width: calc(#{math.div(100%, $columns)} - #{$gap-mobile});

    &:nth-child(n + #{$columns + 1}) {
      @if $gap-mobile != $gap-desktop {
        @include base-mixins.fluid-scale(margin-top, $gap-desktop, $gap-mobile);
      } @else {
        margin-top: $gap-desktop;
      }
    }

    @supports (#{$supports-query}) {
      width: auto;

      &:nth-child(n + #{$columns}) {
        margin-top: 0;
      }
    }

    @include base-mixins.respond($break) {
      width: 100%;

      &:not(first-child) {
        margin-top: $gap-mobile;
      }

      @supports (#{$supports-query}) {
        grid-column: span $columns;
        width: auto;

        /* stylelint-disable-next-line max-nesting-depth */
        &:not(first-child) {
          margin-top: 0;
        }
      }
    }
  }
}
