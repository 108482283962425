@use 'abstracts/mixins/typography';
@use 'abstracts/functions';

.m-footer-subnav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__list {
    columns: 2;
  }

  &__item {
    margin-bottom: functions.padding(x-x-small);
  }

  &__link {
    @include typography.link;
    @include typography.heading-senary;
  }
}
