@use 'abstracts/mixins/appearance';
@use 'abstracts/mixins/layout';
@use 'abstracts/mixins/base';
@use 'abstracts/functions';
@use 'abstracts/variables';

$_break: functions.break(60);

.m-filters-panel {
  @include appearance.bg-color(white);

  overflow: hidden;

  &__wrapper {
    @include layout.container-wide;

    padding-top: functions.padding(x-small);
    padding-bottom: functions.padding(small);

    @include base.respond($_break) {
      @include appearance.bg-color(neutral10);

      padding-top: functions.padding(medium);
      padding-bottom: functions.padding(x-small);
    }
  }

  &__filters {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: functions.padding(small);

    > * {
      @include base.fluid-scale(
        margin-right,
        functions.padding(large),
        functions.padding(small)
      );

      &:last-of-type {
        margin-right: 0;
      }

      @include base.respond($_break - 1) {
        width: 100%;
        margin-right: 0;
      }
    }
  }

  &__apply {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
}
