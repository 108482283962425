@use 'abstracts/functions';
@use 'abstracts/variables';
@use 'abstracts/mixins/base';

$_wide-padding: functions.padding(x-large);
$_narrow-padding: functions.padding(small);
$_break: functions.break(60);
$_class-name: 'm-icon-tabs';

.#{$_class-name} {
  &__header {
    max-width: functions.container-width(narrow);
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }

  &__list-wrapper {
    @include base.respond($_break) {
      display: flex;
      align-items: flex-start;
    }
  }

  &__list {
    @include base.respond($_break) {
      flex: 1 1 auto;
    }
  }

  &__item {
    display: block;
    flex: 1 1 220px; // IE11 needs a set flex-basis to center content
    max-width: 220px;

    @include base.respond($_break) {
      flex: 1 1 auto;
      width: 100%;
      max-width: none;
    }
  }

  &__button {
    @include base.fluid-scale(padding-top, 25px, 10px);
    @include base.fluid-scale(padding-bottom, 35px, 10px);

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 5px;
    color: var(--border-color);
    text-align: center;
    border-bottom: 1px solid var(--border-color);
    transition:
      border-color variables.$duration variables.$timing,
      color variables.$duration variables.$timing;

    @include base.respond($_break) {
      flex-direction: row;
    }

    // Prevent inner tags from blocking click event
    > * {
      pointer-events: none;
    }

    &:hover {
      color: var(--color);
      border-color: var(--color-secondary);

      .#{$_class-name}__icon {
        color: var(--color-secondary);
      }
    }

    &[aria-selected='true'] {
      color: var(--color);
      border-bottom: 1px solid var(--color-secondary);

      .#{$_class-name}__icon {
        color: var(--color-secondary);
        opacity: 1;
      }
    }
  }

  &__icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--border-color);

    @include base.fluid-scale(margin-bottom, 35px, 15px);
    @include base.fluid-scale(
      height,
      functions.padding(large),
      functions.padding(small)
    );

    @include base.respond($_break - 1) {
      --margin-bottom: 0;

      width: 100%;
      max-width: 60px;
      margin-inline-end: 15px;
    }
  }

  &__svg {
    max-width: 96px;
    max-height: 60px;
  }

  &__area-wrapper {
    min-height: var(--tab-area-height);
    margin-top: functions.padding(large);
    transition: min-height variables.$duration variables.$timing;
  }

  &__tab-area {
    &[aria-hidden='true'] {
      display: none;
    }

    &--1 {
      max-width: functions.container-width(narrow);
      margin-right: auto;
      margin-left: auto;
    }

    &--2 {
      @include base.fluid-scale(column-gap, $_wide-padding, $_narrow-padding);

      columns: 2;

      @include base.respond(functions.break(80)) {
        columns: 1;
      }
    }
  }
}
