@use 'abstracts/mixins/typography';

.m-back-link {
  @include typography.copy-label;

  display: inline-flex;
  align-items: center;
  color: var(--color-secondary);

  &__arrow {
    margin-right: 6px;
    transform: rotate(90deg);
  }
}
