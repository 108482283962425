// Variables
// ----------

// Responsive breakpoints
// --------------------------------------------------------
$breakpoints: (
  180: 1800px,
  160: 1600px,
  140: 1440px,
  130: 1280px,
  110: 1120px,
  100: 1024px,
  mobilenav: 930px,
  80: 768px,
  60: 620px,
  50: 550px,
  40: 430px,
  30: 320px,
);

// Measurements
// --------------------------------------------------------
$container-widths: (
  max: 1600px,
  x-wide: 1270px,
  wide: 1180px,
  narrow: 750px,
);
$padding-sizes: (
  x-x-large: 90px,
  x-large: 80px,
  large: 60px,
  medium: 40px,
  small: 30px,
  x-small: 20px,
  x-x-small: 10px,
  grid-gap-desktop: 35px,
  grid-gap-mobile: 30px,
);
$z-stack: (
  header: 10,
  mobilenav: 20,
  search: 30,
  modal: 40,
);
$duration: 0.18s;
$timing: ease;

// Typography
// --------------------------------------------------------
$font-size-base: 17px;
$line-height-base: 1.3;
$font-stack-base: 'roboto', system-ui;
$font-stack-heading: 'freight-sans-pro', $font-stack-base;
$font-weights: (
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
);

// Appearance
// --------------------------------------------------------

// Cross-component variables
// --------------------------------------------------------
