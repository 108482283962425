@use 'abstracts/mixins/layout';
@use 'abstracts/mixins/base';
@use 'abstracts/mixins/typography';
@use 'abstracts/mixins/appearance';
@use 'abstracts/functions';
@use 'abstracts/variables';

$_className: 'm-mobilenav';

.#{$_className} {
  position: fixed;
  top: calc(var(--site-header-height) - 1px);
  right: 0;
  left: 0;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  transition: opacity variables.$duration variables.$timing;

  &--is-visible {
    z-index: functions.z-stack(mobilenav);
    height: calc(100vh - var(--site-header-height) + 1px);
    overflow-y: auto;
    visibility: visible;
    opacity: 1;
  }

  &__inner {
    @include layout.container-x-wide;

    padding-bottom: 25px;
  }

  &__link,
  &__button-text {
    @include typography.link-mobilenav;

    text-align: left;
  }

  &__list-ctas,
  &__list-secondary {
    columns: 2;

    @include base.respond(functions.break(30)) {
      columns: 1;
    }
  }

  &__list-ctas {
    @include base.respond(functions.break(60)) {
      columns: 1;
    }
  }

  ul.#{$_className}__list-ctas {
    @include base.fluid-scale(
      padding-top padding-bottom,
      functions.padding(small),
      functions.padding(x-x-small)
    );
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__item {
    break-inside: avoid;
    padding-bottom: 25px;
  }

  &__arrow {
    transition: transform 0.2s ease-out;

    .#{$_className}__button[aria-expanded="true"] & {
      transform: rotate(180deg);
    }
  }

  &__link {
    &--button {
      display: block;
    }
  }
}

.m-mobilesubnav {
  margin-top: 25px;

  &.is-hidden {
    display: none;
  }

  &__list {
    border-left: 1px solid var(--color-secondary);
  }

  &__item {
    padding-left: functions.padding(x-small);

    & + & {
      margin-top: 25px;
    }
  }

  &__link {
    @include typography.link-mobilenav;
  }
}
