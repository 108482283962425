@use 'abstracts/functions';
@use 'abstracts/mixins/base';
@use 'abstracts/mixins/layout';
@use 'abstracts/mixins/appearance';

$_class-name: 'm-accordion';

.#{$_class-name} {
  &__details {
    appearance: none;
  }

  & + & {
    --margin-top: 0;
  }

  &__summary {
    @include appearance.focus-default;
    @include base.fluid-scale(
      padding-top padding-bottom,
      functions.padding(x-small),
      functions.padding(x-small) - 5
    );

    list-style: none;
    border-bottom: 1px solid var(--border-color);

    // Both appearance: none and list-style: none are needed to remove the arrow
    appearance: none;

    &::-webkit-details-marker {
      display: none;
    }

    &::before {
      display: none;
    }
  }

  // Safari needs an inner wrapper for flex to work properly in summary
  &__summary-inner {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__title {
    flex: 1 1 auto;
  }

  &__open,
  &__closed {
    margin-right: functions.padding(x-x-small);
    margin-left: functions.padding(x-x-small);
    color: var(--color-secondary);
  }

  &__open {
    display: none;

    .#{$_class-name}__details[open] & {
      display: inline;
    }
  }

  &__details[open] &__closed {
    display: none;
  }

  &__text {
    @include base.fluid-scale(
      padding-top,
      functions.padding(medium),
      functions.padding(small)
    );
    @include base.fluid-scale(
      padding-bottom,
      functions.padding(medium) + 10,
      functions.padding(small) + 10
    );
  }
}
