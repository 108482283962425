@use 'abstracts/functions';
@use 'abstracts/mixins/base';
@use 'abstracts/mixins/layout';
@use 'abstracts/mixins/appearance';

$_break: functions.break(100);

.m-patient-table {
  display: grid;
  grid-template:
    '. inner inner'
    / 1fr functions.container-width(wide) 1fr;
  max-width: 100%;
  padding-right: functions.padding(small);
  overflow-x: auto;

  @include base.respond($_break) {
    display: block;
  }

  &__inner {
    grid-area: inner;

    @include base.respond(functions.break(130)) {
      @include layout.container-wide;
    }
  }
}
