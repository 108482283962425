@use 'abstracts/mixins/base';
@use 'abstracts/functions';

$width: functions.image-width(hero);
$height: functions.image-height(hero);

.m-image-ce {
  &--max &__img-wrapper {
    object-fit: cover;

    &::before {
      display: block;
      padding-bottom: calc(100% / (#{$width} / #{$height}));
      content: '';
    }
  }

  &__caption {
    padding-top: functions.padding(x-x-small);
    padding-bottom: functions.padding(x-x-small);
  }

  &--max &__caption {
    padding-right: functions.padding(x-x-small);
  }
}
