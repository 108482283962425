@use 'abstracts/mixins/appearance';

$_duration: 400ms;
$_visibility-delay: $_duration * 0.5;

.m-read-more {
  position: relative;
  transition: max-height #{$_duration} ease-in-out;

  &__button {
    padding-top: 2em;

    &[aria-expanded='true'] {
      position: absolute;

      @include appearance.fade-out;

      z-index: 0;
    }
  }

  &__extra {
    position: relative;
    z-index: 1;
    padding-top: 2em;
    background-color: var(--background-color);

    @include appearance.read-more;
  }
}
