@use 'abstracts/mixins/base';
@use 'abstracts/mixins/typography';
@use 'abstracts/mixins/appearance';
@use 'abstracts/functions';

$_break: functions.break(60);

.m-select-group {
  display: flex;
  align-items: center;

  > .m-select {
    flex: 1 1 auto;

    + .m-select {
      padding-left: functions.padding(x-x-small);
    }
  }
}

.m-select {
  display: inline-flex;
  align-items: center;
  border-bottom: 1px solid var(--color-secondary);

  @include base.respond($_break) {
    display: block;
  }

  &__label {
    padding-right: 5px;
    white-space: nowrap;

    @include base.respond($_break) {
      @include typography.copy-label;

      display: block;
    }
  }

  &__select {
    @include appearance.input-unstyled;

    max-width: 190px;
    padding-top: functions.padding(small) * 0.5;
    padding-right: functions.padding(x-small) + 5px;
    padding-bottom: functions.padding(small) * 0.5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include base.respond($_break) {
      width: 100%;
      max-width: 100%;
    }

    &::-ms-expand {
      display: none;
    }
  }

  &__arrow {
    flex: 0 0 auto;
    margin-left: functions.padding(x-small) * -1;
    color: var(--color-secondary);
    pointer-events: none;
  }
}
