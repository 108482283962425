@use 'abstracts/mixins/base';
@use 'abstracts/functions';

$_wide-padding: functions.padding(x-large);
$_narrow-padding: functions.padding(small);

.m-flowing-columns {
  @include base.fluid-scale(column-gap, $_wide-padding, $_narrow-padding);

  columns: 2;

  &--3 {
    columns: 3;

    @include base.respond(functions.break(100)) {
      columns: 2;
    }
  }

  @include base.respond(functions.break(80)) {
    columns: 1;
  }

  p {
    break-inside: avoid;
  }
}
