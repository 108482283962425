@use 'abstracts/mixins/base';
@use 'abstracts/mixins/layout';
@use 'abstracts/functions';

$_largeWidth: functions.image-width(medium) * 1px;
$_minWidth: 271px;
$_largeHeight: functions.image-height(medium) * 1px;
$_defaultHeight: functions.image-height(small) * 1px;
$_minHeight: 212px;
$_break: functions.container-width(wide);
$_className: 'm-list-slider';
.#{$_className} {
  display: grid;

  // Content should line up with container-width-wide's left margin,
  // But continues on the right to the browser edge
  grid-template:
    'left content content' auto
    / 1fr #{$_break} 1fr;
  max-width: 2000px;
  margin-right: auto;
  margin-left: auto;

  @include base.respond($_break) {
    display: block;
  }

  .m-hero--news + & {
    padding-top: 0;
    margin-top: calc((var(--padding-top) + #{functions.padding(medium)}) * -1);
    background: transparent;
  }

  &__left {
    grid-area: left;
  }

  &__inner {
    grid-area: content;
    width: 100%;
    overflow: visible;

    @include base.fluid-scale(
      padding-left,
      functions.padding(large),
      functions.padding(small),
      $_break
    );

    @include base.respond($_break + functions.padding(large), 'min') {
      padding-left: 0;
    }
  }

  &__body {
    position: relative;
  }

  &__slider {
    @include base.fluid-scale(
      margin-top,
      functions.padding(small),
      functions.padding(small) * 0.5
    );
  }

  &__slide {
    @include base.fluid-scale(max-width, $_largeWidth, $_minWidth);

    // Width set on HTML element for IE11

    & + & {
      @include base.fluid-scale(
        margin-left,
        functions.padding(grid-gap-desktop),
        functions.padding(grid-gap-mobile)
      );
    }
  }

  &__nav {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    pointer-events: none;

    @include base.fluid-scale(height, $_defaultHeight, $_minHeight);
    @include base.fluid-scale(
      padding-left padding-right,
      functions.padding(large),
      functions.padding(x-x-small)
    );

    .#{$_className}--large & {
      @include base.fluid-scale(height, $_largeHeight, $_minHeight);
    }

    .#{$_className}--3-items & {
      @include base.respond(functions.break(130), 'min') {
        display: none;
      }
    }
  }

  &__nav-button {
    &[disabled] {
      visibility: hidden;
    }
  }
}
