@use 'abstracts/mixins/base';
@use 'abstracts/mixins/layout';
@use 'abstracts/functions';
@use 'abstracts/variables';

$_break: functions.break(60);
$_className: 'm-tabs';

.#{$_className} {
  @include base.fluid-scale(margin-top, 50px, functions.padding(x-small));
  @include base.fluid-scale(margin-bottom, 70px, functions.padding(x-small));

  @include base.respond($_break) {
    display: flex;
    justify-content: space-between;
  }

  &__list {
    display: flex;
    justify-content: center;

    @include base.respond($_break) {
      flex-direction: column;
    }
  }

  &__list[aria-expanded='false'] &__tab[aria-selected='false'] {
    @include base.respond($_break) {
      display: none;
    }
  }

  &__tab {
    @include base.respond($_break) {
      text-align: left;
    }
  }

  &__toggle {
    display: none;
    height: 50px;
    margin-left: functions.padding(x-small) - 5;
    color: var(--color-secondary);

    @include base.respond($_break) {
      display: block;
    }

    &[aria-selected="true"] .#{$_className}__toggle-open {
      display: none;
    }

    &[aria-selected="false"] .#{$_className}__toggle-closed {
      display: none;
    }
  }

  &__toggle-open,
  &__toggle-closed {
    display: flex;
    align-items: center;
  }

  &__toggle-text {
    padding-right: functions.padding(x-x-small);
  }

  &__content {
    min-height: 300px;
    opacity: 1;
    transition: opacity variables.$duration variables.$timing;

    > .a-bg-white:first-child {
      --padding-top: 0;
    }

    &--loading {
      opacity: 0;
    }
  }

  &__loading {
    position: absolute;
    right: 0;
    left: 0;
    padding-top: functions.padding(small);
    padding-bottom: functions.padding(x-x-large);
    opacity: 1;
    transition: opacity variables.$duration variables.$timing;

    &[hidden] {
      opacity: 0;
    }
  }
}
