@use 'abstracts/functions';
@use 'abstracts/mixins/layout';
@use 'abstracts/mixins/base';

$_width: functions.image-width(hero);
$_height: functions.image-height(hero);

.m-image-slideshow {
  @include base.fluid-scale(
    --image-slideshow-button-margin,
    functions.padding(large),
    functions.padding(small)
  );
  @include base.fluid-scale(
    --image-slideshow-dots-margin,
    functions.padding(large),
    functions.padding(small)
  );

  position: relative;
  width: 100%;
  max-width: functions.container-width(max);
  margin: 0 auto;

  &--small {
    --image-slideshow-button-margin: 30px;

    @include base.fluid-scale(--image-slideshow-dots-margin, 50px, 30px);
  }

  &__slide {
    width: 100%;
  }

  &__img-wrapper {
    position: relative;
    aspect-ratio: var(--image-slideshow-width, #{$_width}) / var(--image-slideshow-height, #{$_height});
    overflow: hidden;
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__caption {
    @include layout.container-wide;

    width: 100%;
    min-height: functions.padding(small);
    padding-top: functions.padding(x-x-small);
    padding-bottom: functions.padding(x-x-small);
  }

  &__nav {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    width: 100%;
    aspect-ratio: var(--image-slideshow-width, #{$_width}) / var(--image-slideshow-height, #{$_height});
    pointer-events: none;
  }

  &__prev {
    margin-inline-start: var(--image-slideshow-button-margin);
  }

  &__next {
    margin-inline-start: auto;
    margin-inline-end: var(--image-slideshow-button-margin);
  }

  ol.flickity-page-dots {
    position: absolute;
    bottom: 0;
    padding-inline: calc(var(--image-slideshow-dots-margin) - 5px);
  }

  .flickity-page-dots .dot {
    max-width: 28px;
    margin-right: 5px;
    margin-left: 5px;
    border-bottom-width: 2px;
  }
}
