@use '../functions';
@use '../variables';

// State mixins
// --------------------

// Mixins and classes for setting styles based on component state.
// Classes are prepended with `is-` or `has-`.

@mixin has-scroll-lock {
  &.has-scroll-lock {
    position: absolute;
    width: 100%;
    overflow: hidden;
  }
}
