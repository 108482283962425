@use 'abstracts/functions';
@use 'abstracts/mixins/base';
@use 'abstracts/mixins/typography';

.m-number-list {
  list-style: none;
  counter-reset: large-counter;

  &__item {
    @include base.fluid-scale(
      padding-top padding-bottom,
      functions.padding(medium),
      functions.padding(small)
    );

    display: flex;
    counter-increment: large-counter;
    border-top: 1px solid var(--border-color);
    break-inside: avoid;

    &::before {
      @include typography.heading-primary;

      padding-right: functions.padding(small);
      margin-top: 0;
      line-height: 1rem;
      color: var(--color-secondary);
      content: '0' counter(large-counter);
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
}
