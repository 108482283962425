@use 'abstracts/functions';
@use 'abstracts/mixins/base';
@use 'abstracts/mixins/layout';

$_break: functions.break(80);

.m-meet-patients {
  &__items {
    @include base.respond($_break) {
      display: block;
      margin-right: calc(var(--padding-right) * -1);

      &::after {
        display: none;
        content: 'flickity';
      }

      > * {
        // Flickity width set for IE11
        width: 100%;
      }
    }
  }

  &__item {
    @include base.respond($_break) {
      width: 270px;

      & + & {
        margin-left: functions.padding(grid-gap-mobile);
      }
    }
  }
}
