@use 'abstracts/functions';
@use 'abstracts/mixins/layout';
@use 'abstracts/mixins/base';
@use 'abstracts/mixins/appearance';
@use 'abstracts/mixins/typography';

$_break: functions.break(60);
$_width: functions.image-width(hero);
$_height: functions.image-height(hero);

.m-hero {
  width: 100%;
  max-width: functions.container-width(max);
  margin: 0 auto;

  &__inner {
    @include layout.container-wide;

    display: grid;
    grid-template:
      'breadcrumbs breadcrumbs' auto
      'header summary' auto
      / 2fr auto;
    grid-column-gap: functions.padding(small);

    @include base.fluid-scale(
      padding-bottom,
      functions.padding(x-x-large),
      functions.padding(large)
    );
    @include base.fluid-scale(
      padding-top,
      functions.padding(medium),
      functions.padding(small)
    );

    @include base.respond($_break) {
      grid-template:
        'breadcrumbs' auto
        'header' auto
        'summary' auto
        / 1fr;
    }

    &--page-link {
      padding-bottom: functions.padding(large);

      @include base.respond($_break + 1, 'min') {
        grid-template:
          'breadcrumbs summary' auto
          'header summary' auto
          / 2fr auto;
      }
    }

    > * + * {
      @include base.fluid-scale(
        margin-top,
        functions.padding(small),
        functions.padding(x-small)
      );
    }
  }

  &__figure {
    position: relative;
    min-height: 304px;
    max-height: 60vh;
    overflow: hidden;

    &::before {
      display: block;
      padding-bottom: calc(100% / (#{$_width} / #{$_height}));
      content: '';
    }
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__breadcrumbs {
    grid-area: breadcrumbs;
  }

  &__breadcrumbs-list {
    display: flex;
    flex-wrap: wrap;
  }

  &__breadcrumbs-item {
    &:not(:last-child) {
      margin-right: 5px;
      color: var(--color-light);

      &:hover {
        color: inherit;
      }
    }
  }

  &__header {
    grid-area: header;
    justify-self: start;
    font-variant-numeric: tabular-nums;
  }

  &__summary {
    @include base.fluid-scale(max-width, 370px, 245px);

    grid-area: summary;
    align-self: end;
    justify-self: end;

    @include base.respond($_break) {
      align-self: start;
      justify-self: start;
      max-width: none;
    }
  }

  &__page-link {
    @include base.fluid-scale(max-width, 245px, 175px);

    @include base.respond($_break) {
      @include appearance.bg-color(blue);

      --max-width: none;
    }
  }

  &__single-page-link {
    grid-area: summary;
    align-self: end;
    justify-self: end;
    margin-top: 0.75rem;
  }

  &__page-img {
    height: auto;
    object-fit: cover;
    object-position: 50% 50%;

    @include base.respond($_break) {
      display: none;
    }
  }

  &__page-text-container {
    display: flex;
    align-items: center;
    padding:
      functions.padding(small) * 0.5 functions.padding(x-small)
      functions.padding(small) * 0.5 functions.padding(small);

    @include base.respond($_break) {
      padding: 0;
    }
  }

  &__page-text {
    @include typography.link-navigation;

    color: var(--color-secondary);
  }

  &__page-svg {
    flex: 0 0 auto;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
    stroke-width: 1;
  }
}

ul.m-hero__links {
  display: none;
  margin-top: 1rem;

  @include base.respond($_break) {
    display: block;
    flex-direction: column;
    gap: 3px;
  }
}

ul.main-carousel {
  position: relative;
  grid-area: summary;
  align-self: end;
  justify-self: end;
  width: 300px;
  height: auto;
  margin-inline: 40px;

  @include base.fluid-scale(max-width, 245px, 175px);

  .next {
    position: absolute;
    top: 50%;
    left: 100%;
    margin-left: 10px;
  }

  .previous {
    position: absolute;
    top: 50%;
    left: -40px;
    margin-right: 10px;
  }

  @include base.respond($_break) {
    display: none;
  }

  &.flickity-enabled.is-draggable::after {
    display: none;
  }
}

.carousel-cell {
  @include appearance.bg-color(white);

  width: 100%;
  height: fit-content;
  list-style: none;

  @include base.respond($_break) {
    width: inherit;
    height: inherit;
    background-color: inherit;

    @include appearance.bg-color(blue);
  }

  ::marker {
    display: none;
  }
}

.flickity-button {
  background: transparent;
}

.flickity-prev-next-button {
  width: 30px;
  height: 30px;
}

.flickity-button-icon {
  fill: white;
}

.flickity-button:disabled {
  display: none;
}
