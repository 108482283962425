@use 'abstracts/mixins/base';

.m-loading-indicator {
  display: block;
  margin: 0 auto;
  color: var(--color-secondary);

  &__circle {
    opacity: 0.4;
  }

  &__progress {
    animation-name: spin;
    animation-duration: 0.75s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    @include base.reduced-motion {
      opacity: 0;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
    transform-origin: center;
  }

  to {
    transform: rotate(360deg);
    transform-origin: center;
  }
}

@keyframes pulse {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.5;
  }
}
