@use 'abstracts/functions';

.m-skip-link {
  position: fixed;
  top: 0;
  left: 0;
  padding: functions.padding(x-x-small) functions.padding(x-small);
  color: var(--color-secondary);
  transform: translateX(-100%);

  &:focus-visible {
    outline: 0;
    transition: transform 0.2s;
    transform: translateX(0);
  }
}
