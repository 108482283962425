@use 'abstracts/functions';
@use 'abstracts/mixins/typography';
@use 'abstracts/mixins/appearance';

.m-audio {
  &__player {
    display: block;
    width: 80%;
    max-width: 500px;

    @include appearance.focus-default;
  }

  &__caption {
    margin-left: functions.padding(x-small);
  }

  &__transcript {
    @include typography.link-underlined;
    @include typography.copy-small;

    display: inline-block;
    margin-top: functions.padding(x-x-small);
    border-color: var(--color-light);
  }
}
