@use 'abstracts/functions';
@use 'abstracts/variables';
@use 'abstracts/mixins/base';

.m-pagination {
  &__list {
    display: flex;
    align-items: center;
    justify-content: center;

    > * + * {
      @include base.fluid-scale(
        margin-left,
        functions.padding(small),
        functions.padding(x-x-small) * 0.5
      );
    }
  }

  &__prev {
    display: inline-block;

    @include base.fluid-scale(
      padding-right,
      functions.padding(x-small),
      functions.padding(x-x-small)
    );
  }

  &__next {
    display: inline-block;

    @include base.fluid-scale(
      padding-left,
      functions.padding(x-small),
      functions.padding(x-x-small)
    );
  }

  &__arrow {
    @include base.fluid-scale(width height, 56px, 40px);

    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background: var(--color-secondary);
    border-radius: 50%;
    transition: background-color variables.$duration variables.$timing;

    &:hover {
      background-color: var(--color-secondary-dk);
    }

    &--disabled,
    &--disabled:hover {
      background-color: functions.palette(ltblue);
    }
  }

  &__svg {
    @include base.fluid-scale(width height, 23px, 18px);

    margin-bottom: 2px;

    .m-pagination__arrow[rel='prev'] & {
      transform: rotate(180deg);
    }
  }

  &__link {
    display: inline-block;
    padding: 10px;

    &--disabled {
      opacity: 0.6;
    }
  }
}
