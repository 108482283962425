@use 'abstracts/mixins/layout';
@use 'abstracts/mixins/base';
@use 'abstracts/mixins/typography';
@use 'abstracts/functions';

.m-footer {
  padding-bottom: functions.padding(small);

  @include base.respond(functions.break(60)) {
    display: none;
  }

  &__inner {
    @include layout.container-wide;
  }

  &__grid {
    @include layout.grid(
      2,
      functions.padding(x-large),
      functions.padding(small),
      functions.break(80)
    );
    @include base.fluid-scale(
      padding-top padding-bottom,
      functions.padding(x-large),
      functions.padding(medium)
    );
  }

  &__mission {
    @include typography.copy-small;

    color: var(--color-light);

    @include base.respond(functions.break(80)) {
      margin-top: functions.padding(small);
    }

    &::before {
      display: block;
      width: 22px;
      margin-bottom: functions.padding(x-small);
      content: '';
      border-top: 2px solid var(--color-secondary);
    }
  }

  &__copy {
    display: flex;
    text-align: left;

    > * + * {
      margin-left: functions.padding(small);
    }
  }
}
