@use '../abstracts/mixins/typography';
@use '../abstracts/mixins/base' as base-mixins;
@use '../abstracts/functions';
@use '../abstracts/variables';

$_break: functions.break(80);

/* stylelint-disable max-nesting-depth, property-no-unknown */
.m-timeline-nav {
  @include base-mixins.fluid-scale(--padding-top, 100px, 30px);

  --padding-bottom: 30px;

  transition: box-shadow 200ms 0 ease-in;

  @include base-mixins.respond($_break, 'min') {
    position: sticky;
    top: calc(var(--padding-top) * -1 - 30px);
    z-index: 1;

    &.is-pinned {
      box-shadow: 0 8px 8px 0 rgb(0 0 0 / 10%);
    }
  }

  &__inner {
    position: sticky;
    top: -1px;
    z-index: 1;
  }

  &__heading {
    padding-block-end: 25px;

    @include base-mixins.respond($_break, 'min') {
      padding-block-end: 23px;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    row-gap: 0.875rem;
    column-gap: 0.625rem;
  }

  &__link {
    display: block;
    text-align: center;
    border-block-end: 2px solid functions.palette(neutral20);
    transition-timing-function: variables.$timing;
    transition-duration: variables.$duration;
    transition-property: color, border, line-height;

    @include base-mixins.fluid-scale(padding-block-end, 10px, 4px);
    @include base-mixins.fluid-scale(font-size, 26px, 14px);
    @include typography.proto-heading;
    @include typography.copy-bold;
    @include base-mixins.fluid-scale(line-height, 60px, 24px);

    @supports (animation-timeline: scroll()) {
      animation: dot-selected linear both;

      &:hover {
        // stylelint-disable declaration-no-important
        animation: none !important;
      }
    }

    &[data-active='true'] {
      color: functions.palette(blue);
      border-color: functions.palette(blue);
    }

    &:hover {
      color: functions.palette(blue);
      border-color: functions.palette(blue);
    }
  }
}

.m-timeline {
  @supports (animation-timeline: scroll()) {
    /* animation progress is the full page scroll range */
    animation-timeline: scroll(y);
  }

  scroll-margin-block-start: 250px;

  &__intro {
    @include typography.proto-copy;
    @include base-mixins.fluid-scale(font-size, 22px, 20px);

    // If animation-timeline is supported, show blue background when in view.
    @supports (animation-timeline: scroll()) {
      view-timeline-name: --subjectreveal;
      animation-timeline: --subjectreveal;
      animation-name: appear;
      animation-fill-mode: both;
      animation-duration: 1ms;
    }

    // Otherwise, always show blue background
    @supports not (animation-timeline: scroll()) {
      --heading-color: #{functions.palette(blue)};
      --timeline-background-color: #{functions.palette(blue)};
      --color: #{functions.palette(white)};
      --color-secondary: #{functions.palette(white)};
      --color-light: #{functions.palette(white)};
      --color-secondary-dk: #{functions.palette(white)};
    }

    > div {
      padding: 3.125rem;
    }

    > br {
      margin-block-end: 1rem;
    }
  }

  &__list {
    block-size: 100%;

    &::after {
      display: table;
      clear: both;
      content: '';
    }
  }

  &__item {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-block: 50px;
    list-style: none;

    &[data-active='true'] {
      --heading-color: #{functions.palette(blue)};
      --timeline-background-color: #{functions.palette(blue)};
      --color: #{functions.palette(white)};
      --color-secondary: #{functions.palette(white)};
      --color-light: #{functions.palette(white)};
      --color-secondary-dk: #{functions.palette(white)};
    }

    /* stylelint-disable-next-line scss/media-feature-value-dollar-variable */
    @media screen and (min-width: 1250px) {
      inline-size: calc(50% + 1px);

      > div {
        inline-size: 100%;
      }

      &:nth-child(2n-1) {
        align-items: flex-end;
        float: left;
        margin-inline-end: -1px;
        clear: left;
        border-inline-end: 2px solid functions.palette(neutral20);

        > div {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          margin-inline-end: 70px;
        }

        > h3 {
          padding-inline-end: 70px;
          margin-inline-end: -2px;
          border-inline-end: 4px solid functions.palette(neutral40);
        }
      }

      &:nth-child(2n) {
        align-items: flex-start;
        float: right;
        margin-inline-start: -1px;
        clear: right;
        border-inline-start: 2px solid functions.palette(neutral20);

        > div {
          margin-inline-start: 70px;
        }

        > h3 {
          padding-inline-start: 70px;
          margin-inline-start: -2px;
          border-inline-start: 4px solid functions.palette(neutral40);
        }
      }
    }

    img {
      max-inline-size: 100%;
    }
  }

  &__content {
    max-inline-size: 528px;
    inline-size: 100%;
    color: var(--color, functions.palette(black));
    background-color: var(--timeline-background-color, #{functions.palette(neutral10)});
    transition-timing-function: variables.$timing;
    transition-duration: variables.$duration !important;
    transition-property: color, background-color;

    > * {
      --background-color: var(--timeline-background-color, #{functions.palette(neutral10)});

      inline-size: 100%;
    }
  }

  &__year {
    @include typography.proto-heading;
    @include typography.copy-bold;
    @include base-mixins.fluid-scale(font-size, 60px, 40px);

    margin-block-end: 1.5rem;
    color: var(--heading-color, inherit);
    transition: color variables.$duration variables.$timing !important;
  }

  &__text {
    @include typography.proto-copy;
    @include base-mixins.fluid-scale(font-size, 18px, 16px);
    @include base-mixins.fluid-scale(line-height, 30px, 24px);

    display: flex;
    flex-direction: column;
    gap: 1.875rem;
    padding: 3.125rem 3.125rem 3.75rem;
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }
}

@keyframes dot-selected {
  0%,
  100% {
    color: functions.palette(black);
    border-color: functions.palette(neutral20);
  }

  50% {
    color: functions.palette(blue);
    border-color: functions.palette(blue);
  }
}

@keyframes appear {
  0%,
  100% {
    border: 5px solid transparent;
  }

  50% {
    --heading-color: #{functions.palette(blue)};
    --timeline-background-color: #{functions.palette(blue)};
    --color: #{functions.palette(white)};
    --color-secondary: #{functions.palette(white)};
    --color-light: #{functions.palette(white)};
    --color-secondary-dk: #{functions.palette(white)};
  }
}
