.m-htmlembed {
  break-inside: avoid;

  > * {
    max-width: 100%;
  }

  &--right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &--center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__iframe {
    width: 100%;
    border: 0;
  }
}
