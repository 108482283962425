@use 'abstracts/functions';
@use 'abstracts/mixins/layout';

.m-patient {
  &__details {
    @include layout.grid(2, 0, 0);
  }

  &__detail-title,
  &__detail-desc {
    padding: functions.padding(x-x-small) 0;
    border-bottom: 1px solid var(--border-color);
  }

  &__detail-title {
    font-weight: functions.font-weight(medium);
  }
}
