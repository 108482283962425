@use 'abstracts/functions';
@use 'abstracts/mixins/layout';
@use 'abstracts/mixins/base';
@use 'abstracts/mixins/typography';

.m-hero-model {
  width: 100%;

  &__inner {
    @include layout.container-wide;

    display: grid;
    grid-template:
      'body image' auto
      / 1fr 55%;
    width: 100%;
    margin: 0 auto;

    @include base.respond(functions.break(110)) {
      padding-right: 0;
    }

    @include base.respond(functions.break(60)) {
      grid-template:
        'image'
        'body'
        1fr;
      padding-left: 0;
    }
  }

  &__figure,
  &__body {
    align-self: center;
  }

  &__figure {
    position: relative;
    grid-area: image;
    width: 100%;
    max-width: functions.image-width(large) * 1px;

    @include base.respond(functions.break(60)) {
      width: 100%;
    }
  }

  &__figcaption {
    position: absolute;
    right: 32px;
    bottom: 22px;

    @include base.respond(functions.break(30)) {
      right: 17px;
      bottom: 12px;
    }
  }

  &__img {
    object-fit: cover;
  }

  &__body {
    @include base.fluid-scale(
      padding-top padding-bottom padding-right,
      functions.padding(small),
      functions.padding(medium)
    );

    @include base.respond(functions.break(60)) {
      padding-left: functions.padding(medium);
    }

    grid-area: body;
  }

  &__back {
    @include base.fluid-scale(margin-bottom, 50px, functions.padding(x-small));
  }

  &__subtitle,
  &__summary,
  &__topics {
    @include base.fluid-scale(margin-top, 24px, functions.padding(small));
  }

  &__subtitle {
    @include typography.heading-quinary;
  }

  &__summary {
    max-width: 370px;
  }

  &__social {
    @include base.fluid-scale(margin-top, 38px, functions.padding(x-small));
  }

  &__topics-list {
    display: flex;
    flex-wrap: wrap;
  }

  &__topics-item {
    margin-right: functions.padding(x-x-small);
    margin-bottom: functions.padding(x-x-small);

    &:last-of-type {
      margin-right: 0;
    }
  }
}
