@use 'abstracts/functions';
@use 'abstracts/mixins/base';
@use 'abstracts/mixins/layout';

.m-stats {
  width: 100%;
  max-width: functions.container-width(max);
  margin: 0 auto;

  &__inner {
    @include layout.container-wide;
    @include base.fluid-scale(padding-bottom, 40px, 30px);
  }

  &__svg {
    flex: 0 0 auto;
    max-width: 64px;
    max-height: 40px;
    margin-right: 10px;
    color: var(--color-secondary);

    @include base.respond(functions.break(30)) {
      margin-bottom: functions.padding(x-x-small);
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    display: flex;
    align-items: center;
    max-width: functions.container-width(narrow);

    @include base.fluid-scale(padding-top, 30px, 20px);

    @include base.respond(functions.break(30)) {
      flex-direction: column;
      justify-content: center;
    }

    &:not(:last-child) {
      @include base.fluid-scale(margin-right, 50px, 30px);
    }
  }
}
