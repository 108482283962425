@use 'abstracts/functions';
@use 'abstracts/mixins/base';

$_gap: functions.padding(grid-gap-desktop);
$_gapMobile: functions.padding(x-x-small);

.m-stafflist {
  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include base.fluid-scale(margin-left, $_gap * -1, $_gapMobile * -1);
    @include base.fluid-scale(
      margin-bottom,
      -50px,
      functions.padding(small) * -1
    );

    @include base.respond(functions.break(60)) {
      display: block;
    }

    > * {
      flex: 1 1 calc(33% - #{$_gap});

      // Make sure wrapped containers are not larger than their sibling containers
      @include base.fluid-scale(max-width, 370px, 180px);
      @include base.fluid-scale(margin-left, $_gap, $_gapMobile);
      @include base.fluid-scale(padding-bottom, 50px, functions.padding(small));

      @include base.respond(functions.break(60)) {
        flex: 1 1 auto;
        max-width: 100%;
      }
    }
  }
}
