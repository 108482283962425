@use 'abstracts/functions';
@use 'abstracts/variables';
@use 'abstracts/mixins/appearance';

// Appearance
// --------------------

// Appearance mixins contain primarily texture parameters: background-color, transparency, borders, etc.

// These should be limited to appearance-related properties only.

// Visibility
// --------------------------------------------------------
.a-pos-rel {
  position: relative;
}

.a-pos-abs {
  position: absolute;
}

.a-hidden {
  @include appearance.hidden;
}

.a-hide-overflow {
  overflow: hidden;
}

// Color
// --------------------------------------------------------
// Create a `.a-color-` class for each color in $palette map
@each $name, $value in functions.token(colors) {
  .a-color-#{"" + $name} {
    color: $value;
  }
}

.a-color-secondary {
  color: var(--color-secondary);
}

// Create a `.a-bg-` class for each color in $palette map
@each $name, $value in functions.token(colors) {
  .a-bg-#{"" + $name} {
    @include appearance.bg-color($name);
  }
}

.a-tag {
  display: inline-flex;
  align-items: center;
  min-height: 32px;
  padding: 5px 16px;
  background: functions.palette(white);

  .a-bg-white {
    background: functions.palette(neutral10);
  }
}

// Animations
// --------------------------------------------------------
.a-slide-in {
  animation: slidein variables.$duration ease-in forwards;
}

.a-slide-out {
  animation: slideout variables.$duration ease-out forwards;
}

.a-fade-out {
  @include appearance.fade-out;
}

@keyframes slidein {
  0% {
    max-height: 0;
  }

  100% {
    max-height: 100vh;
  }
}

@keyframes slideout {
  0% {
    max-height: 100vh;
  }

  100% {
    max-height: 0;
  }
}
