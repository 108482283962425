@use '../abstracts/mixins/typography';
@use '../abstracts/mixins/appearance';

// Typography utility classes
// -----------------------------------
// These should be limited to `font` and other typographic properties only.

// Alignment
// --------------------------------------------------------
.t-align-center {
  text-align: center;
}

// Headings
// --------------------------------------------------------
.t-heading-primary {
  @include typography.heading-primary;
}

.t-heading-secondary {
  @include typography.heading-secondary;
}

.t-heading-tertiary {
  @include typography.heading-tertiary;
}

.t-heading-quaternary {
  @include typography.heading-quaternary;
}

.t-heading-quinary {
  @include typography.heading-quinary;
}

// Copy
// --------------------------------------------------------
.t-copy-primary {
  @include typography.copy-primary;
}

.t-copy-bold {
  @include typography.copy-bold;
}

.t-copy-label {
  @include typography.copy-label;
}

.t-copy-small {
  @include typography.copy-small;
}

.t-copy-caption {
  @include typography.copy-caption;
}

.t-copy-x-large {
  @include typography.copy-x-large;
}

.t-copy-x-x-large {
  @include typography.copy-x-x-large;
}

.t-copy-nowrap {
  white-space: nowrap;
}

// Links
// --------------------------------------------------------
.t-link {
  @include typography.link;
}

.t-link-underlined {
  @include typography.link-underlined;
}

// Lists
// --------------------------------------------------------
.t-list-unstyled {
  @include typography.list-unstyled;
}

.t-list-unordered-primary {
  @include typography.list-unordered-primary;
}

.t-list-ordered-primary {
  @include typography.list-ordered-primary;
}

.t-list-item-checkmark {
  @include typography.list-item-checkmark;
}

// Alignment
// --------------------------------------------------------
.t-align-center {
  text-align: center;
}

.t-align-left {
  text-align: left;
}

.t-align-right {
  text-align: right;
}

// Editor styles
// --------------------------------------------------------
.t-left-border,
li.t-left-border {
  @include typography.left-border;

  list-style: none;
}
