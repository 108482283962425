@use 'abstracts/functions';
@use 'abstracts/variables';
@use 'abstracts/mixins/base';

.m-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: functions.z-stack(modal);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding: functions.padding(x-x-small);
  margin: auto;
  overflow: hidden;
  background-color: rgb(0 0 0 / 29%);
  border: none;
  opacity: 1;
  transition: opacity var(--fade-timeout) variables.$timing;

  &[aria-hidden='true'] {
    opacity: 0;
  }

  &__inner {
    @include base.fluid-scale(
      padding-top,
      functions.padding(large),
      functions.padding(x-small)
    );

    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: functions.container-width(x-wide);
    max-height: 85vh;
    overflow: hidden;
  }

  &__close-wrapper {
    text-align: right;

    @include base.fluid-scale(
      padding-left padding-right,
      functions.padding(large),
      functions.padding(x-small)
    );
    @include base.fluid-scale(
      padding-bottom,
      functions.padding(large),
      functions.padding(x-x-small)
    );
  }

  &__body {
    @include base.fluid-scale(
      padding-right padding-left,
      functions.padding(large) * 2 + 5,
      functions.padding(x-x-small) + functions.padding(x-small)
    );

    overflow-y: auto;

    // Fixes missing padding on scroll
    &::after {
      display: block;
      content: '';

      @include base.fluid-scale(
        height,
        functions.padding(large),
        functions.padding(x-x-small)
      );
    }
  }
}
