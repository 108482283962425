// Supplementary CSS reset to postcss-normalize

/* Set core body defaults */
body {
  text-rendering: optimizespeed;
  margin: 0;
}

/* Set core root defaults */
html {
  scroll-behavior: smooth;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  padding: 0;
  margin: 0;
  list-style: none;
}

figure {
  margin: 0;
}

/* Remove all animations and transitions for people that prefer not to see them */
/* stylelint-disable scss/media-feature-value-dollar-variable, declaration-no-important  */
@media (prefers-reduced-motion: reduce) {
  * {
    transition-duration: 0.01ms !important;
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    scroll-behavior: auto !important;
  }
}
/* stylelint-enable */

/* Remove margin from blockquote */
blockquote {
  margin: 0;
}

/* Remove input styling */
input {
  font: inherit;
  background: transparent;
  border: none;
}
