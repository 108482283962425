@use 'abstracts/mixins/layout';
@use 'abstracts/mixins/base';
@use 'abstracts/mixins/typography';
@use 'abstracts/functions';

.m-subnav {
  @include base.fluid-scale(padding-top, 35px, 20px);
  @include base.fluid-scale(padding-bottom, 70px, 50px);

  position: absolute;
  top: var(--site-header-height);
  right: 0;
  left: 0;
  z-index: -1; // relative to it's parent
  border-bottom: 1px solid var(--border-color);
  transform: translateY(-150%);

  &.is-hidden,
  &.is-visible {
    transition: transform 0.4s ease-out;
  }

  &.is-visible {
    transform: translateY(0);
  }

  &__inner {
    @include layout.proto-container(1025px);

    margin-right: auto;
    margin-left: auto;
  }

  &__list {
    @include layout.grid(3, 45px, 25px, functions.break(80));
  }

  &__img-wrapper {
    overflow: hidden;
  }

  &__link-text {
    @include typography.heading-quaternary;

    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 15px;
    color: var(--color-secondary);

    &:hover > *:first-child {
      @include typography.underline-animation;
    }
  }

  &__page-item {
    & + & {
      @include base.fluid-scale(margin-top, 5px, 0);
    }
  }

  &__page-link {
    @include typography.link;
    @include typography.copy-small;

    color: var(--color-light);
  }
}
