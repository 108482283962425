@use 'abstracts/functions';
@use 'abstracts/variables';
@use 'abstracts/mixins/base';

.m-social-icons {
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-right: auto;
    margin-left: auto;

    .has-flexbox-gap & {
      /* autoprefixer: ignore next */
      gap: functions.padding(x-small);
    }
  }

  &__item {
    .no-flexbox-gap & + & {
      margin-left: functions.padding(x-small);
    }
  }

  &__link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--background-color);
    background-color: var(--color-secondary);
    border-radius: 100%;
    transition: background-color variables.$duration variables.$timing;
    block-size: 2.5rem;
    inline-size: 2.5rem;

    &:hover {
      background-color: var(--color-secondary-dk);
    }
  }

  .m-hero-model &__link {
    width: 2.312rem;
    height: 2.312rem;
  }
}
