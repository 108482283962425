@use 'abstracts/functions';
@use 'abstracts/mixins/layout';
@use 'abstracts/mixins/base';
@use 'abstracts/mixins/typography';

$_break: functions.break('mobilenav');

.m-aux-nav {
  @include layout.container-x-wide;

  @include base.respond($_break) {
    display: none;
  }

  &__list {
    display: flex;
    justify-content: flex-end;
  }

  &__item {
    & + & {
      margin-inline-start: 25px;
    }
  }

  &__link {
    @include typography.link-navigation;

    display: block;
    padding: functions.padding(x-x-small) 0;
    color: var(--color-light);

    &--active {
      border-bottom: 1px solid var(--color-secondary);
    }

    &:hover,
    &--active {
      color: initial;
    }

    &--report {
      font-weight: functions.font-weight(bold);
      color: var(--color-secondary);
    }
  }
}
