@use 'abstracts/functions';
@use 'abstracts/mixins/layout';
@use 'abstracts/mixins/base';

.m-search-tabs {
  @include layout.container-wide;
  @include base.fluid-scale(
    padding-top padding-bottom,
    70px,
    functions.padding(medium)
  );

  &__list {
    display: flex;
    justify-content: center;

    @include base.respond(functions.break(60)) {
      .m-tab + .m-tab {
        --margin-left: #{functions.padding(x-small)};
      }
    }
  }
}
