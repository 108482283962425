@use 'abstracts/functions';
@use 'abstracts/mixins/layout';
@use 'abstracts/mixins/base';

$_countSize: 28px;
$_iconSize: 86px;

.m-patient-count {
  @include base.fluid-scale(padding-top, 30px, 17px);
  @include base.fluid-scale(padding-bottom, 50px, 23px);

  &__inner {
    @include layout.container-wide;
  }

  &__body {
    padding: functions.padding(small) 0;
  }

  ul.m-patient-count__list {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    row-gap: functions.padding(x-small);
    margin: 0 calc(var(--padding-left) / 1.5 * -1);

    @include base.respond(functions.break(80)) {
      grid-template-columns: repeat(auto-fit, minmax(auto, 130px));
      margin: 0 calc(var(--padding-left) * -1);
    }

    @include base.respond(functions.break(50)) {
      justify-content: center;
    }
  }

  &__item {
    text-align: center;

    &--disabled {
      opacity: 0.3;
    }
  }

  &__icon {
    position: relative;
    display: inline-block;
  }

  &__svg {
    max-width: $_iconSize;
  }

  &__count {
    position: absolute;
    top: -5px;
    left: #{$_iconSize - $_countSize + 5};
    min-width: $_countSize;
    padding: 0 5px;
    line-height: $_countSize;
    color: functions.palette(white);
    background: functions.palette(teal);
    border-radius: #{$_countSize * 0.5};
  }

  &__name {
    display: block;
  }
}
