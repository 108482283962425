@use 'abstracts/mixins/base';
@use 'abstracts/mixins/appearance';
@use 'abstracts/functions';

$_labelMax: 90px;
$_break: functions.break(60);

.m-form {
  @include base.fluid-scale(--form-gap, 15px, 10px);

  // Required to get flex to work inside a fieldset
  legend {
    float: left;
  }

  &__fieldgroup {
    display: flex;
    align-items: flex-start;

    @include base.respond($_break) {
      flex-wrap: wrap;
    }
  }

  &__label {
    display: inline-block;
    flex: 0 0 auto;
    padding: var(--form-gap) var(--form-gap) var(--form-gap) 0;

    &:first-child {
      width: $_labelMax;

      @include base.respond($_break) {
        width: auto;
      }
    }
  }

  &__input,
  &__select {
    flex: 1 1 auto;
    width: 100%;
    min-width: 0; // Fixes layout bug in Firefox
    margin-bottom: var(--form-gap);

    &:not(:last-child) {
      min-width: 40%;
      margin-right: var(--form-gap);

      @include base.respond($_break) {
        max-width: none;
        margin-right: 0;
      }
    }
  }

  &__input,
  &__select-input {
    @include appearance.input-unstyled;

    min-height: 46px;
    padding: var(--form-gap);
    background-color: functions.palette(white);
    border: 1px solid var(--border-color);

    &::placeholder {
      color: var(--color-light);
      opacity: 0.8;
    }
  }

  &__select {
    display: inline-flex;
    align-items: center;
  }

  &__select-input {
    width: 100%;
    padding-right: functions.padding(small);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__select-arrow {
    margin-left: functions.padding(small) * -1;
    color: var(--color-secondary);
    pointer-events: none;
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-top: functions.padding(small);
    margin-left: $_labelMax;

    @include base.respond($_break) {
      flex-direction: column;
      justify-content: center;
      margin-left: 0;
    }
  }

  &__required {
    &::after {
      padding-left: 5px;
      color: functions.palette(red);
      content: '*';
    }
  }

  &__error {
    margin-bottom: functions.padding(small);
  }
}
