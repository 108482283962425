@use 'abstracts/functions';
@use 'abstracts/variables';
@use 'abstracts/mixins/base';

$_mobileSize: 40px;

.m-page-arrow {
  @include base.fluid-scale(width height, 56px, $_mobileSize);

  color: var(--color-secondary);
  transition: color variables.$duration variables.$timing;

  &:hover {
    color: var(--color-secondary-dk);
  }

  &--large {
    @include base.fluid-scale(width height, 77px, $_mobileSize);
  }

  &--medium {
    @include base.fluid-scale(width height, 63px, $_mobileSize);
  }

  &--small {
    @include base.fluid-scale(width height, 45px, $_mobileSize);
  }

  &--previous {
    transform: rotate(180deg);
  }

  &__arrow {
    fill: var(--background-color);
  }
}
