@use 'abstracts/functions';
@use 'abstracts/mixins/layout';
@use 'abstracts/mixins/base';
@use 'abstracts/mixins/typography';

$_imgWidth: 174px;
$_dateWidth: 79px;

.m-quote-slideshow {
  position: relative;
  max-width: functions.container-width(wide);
  margin: 0 auto;

  &__nav {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding-right: functions.padding(x-x-small);
    padding-left: functions.padding(x-x-small);
    pointer-events: none;
  }

  &__prev,
  &__next {
    pointer-events: all;
  }

  &__slider {
    @include layout.container-narrow;
  }

  &__slide,
  ol.flickity-page-dots {
    width: 100%;
  }

  &__text {
    margin-top: functions.padding(small);
    font-style: normal;
  }

  &__slide-footer {
    display: flex;
    align-items: center;
    padding-top: functions.padding(small);
    padding-bottom: functions.padding(small);

    @include base.respond(functions.break(60)) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__attribution {
    padding-bottom: functions.padding(small);
  }

  &__figure {
    position: relative;
    padding-right: 14px;
    padding-bottom: 24px;
    margin-right: functions.padding(x-small);

    @include base.respond(functions.break(60)) {
      margin-right: 0;
    }
  }

  &__image {
    @include base.fluid-scale(width height, $_imgWidth, $_imgWidth - 30);

    border-radius: $_imgWidth;
    object-fit: cover;
  }

  &__figcaption {
    @include typography.copy-primary;
    @include base.fluid-scale(width height, $_dateWidth, $_dateWidth - 10);

    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    font-size: 14px;
    color: var(--background-color);
    text-align: center;
    background-color: var(--color-secondary);
    border-radius: $_dateWidth;
  }

  .flickity-page-dots .dot {
    max-width: 28px;
    margin-right: 5px;
    margin-left: 5px;
    border-bottom-width: 2px;
  }
}
