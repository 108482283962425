@use 'abstracts/functions';
@use 'abstracts/mixins/base';

$_break: functions.break(60);

.m-hero-multi-images {
  display: flex;
  justify-content: space-between;

  @include base.fluid-scale(
    padding-bottom,
    functions.padding(large),
    functions.padding(small)
  );

  &__left {
    display: flex;
    flex: 1 1 56%;
    flex-direction: column;
    justify-content: space-between;
    max-width: 664px;

    @include base.respond(functions.break(80) + 1px, 'min') {
      margin-top: functions.padding(grid-gap-mobile);
    }
  }

  &__right {
    flex: 1 1 44%;
    padding-left: functions.padding(grid-gap-mobile);

    @include base.respond($_break) {
      display: none;
    }
  }

  &__header {
    margin-top: functions.padding(grid-gap-mobile);
  }

  &__figure2 {
    margin-bottom: functions.padding(grid-gap-mobile);
  }

  &__figure3 {
    @include base.fluid-scale(
      margin-right,
      functions.padding(large),
      functions.padding(grid-gap-mobile)
    );
  }
}
