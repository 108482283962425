@use 'abstracts/mixins/base';
@use 'abstracts/mixins/typography';
@use 'abstracts/functions';

$_break: functions.break(60);
$_className: 'm-tab';

.#{$_className} {
  & + & {
    @include base.fluid-scale(
      margin-left,
      functions.padding(large),
      functions.padding(x-x-small)
    );

    @include base.respond($_break) {
      --margin-left: 0;
    }
  }

  &__link {
    @include typography.link-navigation;

    display: inline-block;
    padding: functions.padding(small) * 0.5;

    @include base.respond($_break) {
      padding: functions.padding(x-x-small) 0;
    }

    &[aria-selected='true'],
    &--active {
      font-weight: functions.font-weight(semibold);
      background-size: 100% 1px;

      @include typography.underline;
    }
  }
}
