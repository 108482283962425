@use 'abstracts/mixins/base';
@use 'abstracts/mixins/typography';
@use 'abstracts/functions';

$_breakMin: functions.break(60);
$_breakMid: functions.break(80);
$_breakMax: functions.break(110);

.m-donate {
  color: var(--color-secondary-dk);

  &__inner {
    // IE11 requires a mobile first approach for grids
    display: grid;
    grid-template:
      'image' auto
      'buttons' auto
      'text' auto
      / 1fr;

    @include base.fluid-scale(
      grid-gap,
      functions.padding(medium),
      functions.padding(small)
    );

    @media all and (min-width: $_breakMin) and (max-width: $_breakMid) {
      grid-template:
        'image buttons' auto
        'text text' auto
        / 1fr 1fr;
    }

    @media all and (min-width: $_breakMid) and (max-width: $_breakMax) {
      grid-template:
        'image buttons' auto
        'image text' auto
        / 1fr 1fr;
    }

    @include base.respond(functions.break(110), 'min') {
      grid-template:
        'image text buttons' auto
        / 1fr 1fr 1fr;
    }
  }

  &__figure {
    grid-area: image;
  }

  &__header {
    display: none;

    @include base.respond(functions.break(110)) {
      display: block;
    }
  }

  &__header,
  &__text-header {
    @include base.fluid-scale(
      padding-bottom,
      functions.padding(medium),
      functions.padding(x-small)
    );
  }

  &__text-header {
    display: block;

    @include base.respond(functions.break(110)) {
      display: none;
    }
  }

  &__text {
    grid-area: text;
  }

  &__buttons {
    grid-area: buttons;
  }

  &__item {
    display: flex;
    align-items: center;

    & + & {
      @include base.fluid-scale(margin-top, 25px, functions.padding(x-small));
    }

    @include base.respond(functions.break(30)) {
      flex-wrap: wrap;

      > * + * {
        margin-top: functions.padding(x-x-small);
        margin-bottom: functions.padding(x-x-small);
      }
    }
  }

  &__button {
    @include typography.copy-primary;

    width: 130px;
    margin-right: functions.padding(x-x-small);
    font-weight: functions.font-weight(bold);
    text-align: center;

    @include base.respond(functions.break(30)) {
      display: block;
      width: 100%;
    }
  }
}
