@use 'abstracts/mixins/appearance';
@use 'abstracts/mixins/layout';
@use 'abstracts/mixins/base';
@use 'abstracts/functions';
@use 'abstracts/variables';

$_break: functions.break(60);

.m-search-form {
  @include base.respond($_break) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: functions.z-stack(search);
    width: 100%;
    height: 100vh;
    overflow: auto;
  }

  &__search {
    @include layout.container-wide;

    display: flex;
    align-items: center;
    padding-top: functions.padding(x-small);

    > * + * {
      @include base.fluid-scale(
        margin-left,
        functions.padding(small),
        functions.padding(x-small)
      );

      @include base.respond($_break - 1) {
        width: 100%;
        margin-left: 0;
      }
    }

    @include base.respond($_break) {
      display: block;
    }
  }

  &__input-wrapper {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    margin-left: 0;

    @include base.respond($_break) {
      margin-top: functions.padding(x-small);
      margin-bottom: functions.padding(medium);
      border-bottom: 1px solid var(--color-secondary);
    }
  }

  &__input {
    @include appearance.input-unstyled;
    @include base.fluid-scale(
      margin-left,
      functions.padding(small),
      functions.padding(x-small)
    );

    flex: 1 1 auto;
    padding-top: functions.padding(small) * 0.5;
    padding-bottom: functions.padding(small) * 0.5;
    border-bottom: 1px solid var(--color-secondary);

    @include base.respond($_break) {
      margin-bottom: functions.padding(medium);
      margin-bottom: 0;
      border-bottom: none;
    }

    &.focus-visible {
      outline: 0;
    }
  }

  &__filter-button {
    @include base.fluid-scale(
      padding-top padding-bottom,
      functions.padding(small),
      functions.padding(small) * 0.5
    );
    @include base.fluid-scale(
      padding-left padding-right,
      functions.padding(small),
      functions.padding(x-x-small)
    );

    display: flex;
    align-items: center;
    transition: background-color variables.$duration variables.$timing;

    &[aria-expanded='true'] {
      background-color: functions.palette(white);
    }

    // Hide filter toggle on mobile
    @include base.respond($_break) {
      display: none;
    }

    > * {
      pointer-events: none;
    }
  }

  &__filter-icon {
    margin-right: 12px;
    color: var(--color-secondary);
  }

  &__selected {
    @include layout.container-wide;

    padding-top: 28px;
    padding-bottom: functions.padding(medium);
  }

  &__selected-list {
    display: flex;
  }

  &__selected-item {
    @include base.fluid-scale(
      margin-right,
      functions.padding(medium),
      functions.padding(x-small)
    );

    color: var(--color-secondary);

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__remove-svg {
    margin-right: functions.padding(x-x-small);
    margin-bottom: -1px;
  }

  &__remove-text,
  &__remove-svg {
    pointer-events: none;
  }

  &__back-wrapper {
    @include layout.container-wide;

    padding-top: functions.padding(x-small);
  }

  &__back {
    padding: functions.padding(x-x-small) 0;
    color: var(--color-secondary);

    @include base.respond($_break + 1, 'min') {
      display: none;
    }
  }

  &__toggle-wrapper {
    padding-top: functions.padding(x-small);
    padding-bottom: functions.padding(x-small);
    background-color: functions.palette(neutral10);

    @include base.respond($_break + 1, 'min') {
      display: none;
    }
  }

  &__toggle {
    display: block;
    margin: 0 auto;
  }
}
