@use 'abstracts/mixins/base';
@use 'abstracts/mixins/appearance';
@use 'abstracts/variables';
@use 'abstracts/functions';

$_width: functions.image-width(x-small);
$_height: functions.image-height(x-small);

.m-image-link {
  display: block;
}

.m-image {
  position: relative;
  overflow: hidden;

  &::before {
    display: block;
    padding-bottom: calc(100% / (#{$_width} / #{$_height}));
    content: '';
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &--cover {
      object-fit: cover;
    }

    .m-image-link & {
      transition: transform variables.$duration variables.$timing;
    }

    .m-image-link:hover & {
      transform: scale(1.1);

      @include base.reduced-motion {
        opacity: 0.9;
        transform: none;
      }
    }
  }
}
