@use 'abstracts/mixins/base';
@use 'abstracts/functions';

.m-hero-caption {
  @include base.fluid-scale(
    padding-top padding-bottom,
    functions.padding(small),
    functions.padding(x-x-small)
  );
}
