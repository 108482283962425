@use 'abstracts/mixins/layout';
@use 'abstracts/mixins/base';
@use 'abstracts/mixins/typography';
@use 'abstracts/mixins/appearance';
@use 'abstracts/functions';
@use 'abstracts/variables';

$_break: functions.break('mobilenav');
$_className: 'm-nav';

.#{$_className} {
  @include appearance.bg-color(white);

  transition: background-color 0.3s ease-out;

  @include base.respond($_break + 1, 'min') {
    border-bottom: 1px solid var(--border-color);
  }

  &.has-nav-untoggled {
    border-bottom: 1px solid transparent;
  }

  &.has-nav-toggled {
    @include appearance.bg-color(blue);
  }

  &.has-search-toggled {
    @include base.respond($_break) {
      @include appearance.bg-color(neutral10);
    }
  }

  &__inner {
    @include layout.container-x-wide;
    @include base.fluid-scale(padding-top, 27px, 10px);
    @include base.fluid-scale(padding-bottom, 37px, 8px);

    display: flex;
    align-items: center;
  }

  &__logo {
    flex: 1 1 auto;
    color: functions.palette(blue);
  }

  &__logo-link {
    display: block;

    .#{$_className}.has-search-toggled &,
    .#{$_className}.has-nav-toggled & {
      @include base.respond($_break) {
        animation-name: hide-logo;
        animation-duration: 0.3s;
        animation-timing-function: ease-out;
        animation-fill-mode: forwards;
      }
    }
  }

  &__logo-svg, &__logo-img {
    max-width: 100%;

    @include base.fluid-scale(width, 185px, 130px);
    @include base.fluid-scale(height, 86px, 69px);
  }

  &__nav {
    @include base.respond($_break) {
      display: none;
    }
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__item {
    & + & {
      @include base.fluid-scale(
        margin-left,
        functions.padding(medium),
        functions.padding(x-x-small)
      );
    }
  }

  &__button {
    @include typography.link-navigation;

    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center;

    &[aria-expanded='true'] {
      font-weight: functions.font-weight(bold);

      @include typography.underline;
    }

    &--active {
      @include typography.underline;
    }
  }

  &__search {
    color: var(--color-secondary);

    @include base.respond(functions.break('mobilenav') + 1px, 'min') {
      position: relative;
    }
  }

  &__toggle {
    @include base.fluid-scale(margin-left, 25px, 15px);
    @include appearance.focus-default;

    width: 30px;
    color: var(--color-secondary);
    text-align: center;
    transition: color 0.3s ease-out;

    &--menu {
      display: none;

      @include base.respond($_break) {
        display: block;
      }
    }
  }

  &__toggle[aria-expanded='true'] &__toggle-open {
    display: none;
  }

  &__toggle[aria-expanded='false'] &__toggle-close {
    display: none;
  }
}

@keyframes hide-logo {
  0% {
    opacity: 1;
  }

  99% {
    opacity: 0;
  }

  100% {
    pointer-events: none;
    opacity: 0;
  }
}
