@use '../abstracts/mixins/layout';
@use '../abstracts/variables';

// Component Layout
// -------------------

// Layout-only mixins and utility classes.

// They may contain:
// Nested classes/elements that have structural parameters only

// Utility Spacing Classes
// --------------------------------------------------------

// Create a a padding class in each direction for each value in $padding-sizes map
@each $name, $value in variables.$padding-sizes {
  $dir: 'top', 'right', 'bottom', 'left';

  @each $d in $dir {
    .l-pad-#{$d}--#{$name} {
      padding-#{$d}: $value;
    }
  }
}

// Containers
// --------------------------------------------------------
.l-container-max {
  @include layout.container-max;
}

.l-container-wide {
  @include layout.container-wide;
}

.l-container-narrow {
  @include layout.container-narrow;
}
