@use 'abstracts/functions';
@use 'abstracts/mixins/base';
@use 'abstracts/mixins/layout';

$_break: functions.break('mobilenav');

.m-notification {
  max-height: var(--site-notification-height);
  overflow: hidden;
  opacity: 1;
  transition: max-height 0.2s ease-out, opacity 0.2s ease-out;

  @include base.respond($_break) {
    border-bottom: 1px solid var(--border-color);
  }

  &[aria-hidden='true'] {
    max-height: 0;
    border-bottom: 0;
    opacity: 0;

    @mixin reduced-motion() {
      display: none;
    }
  }

  &__inner {
    @include layout.container-x-wide;

    display: flex;
    align-items: center;
  }

  &__text {
    flex: 1 1 auto;
    text-align: center;

    @include base.fluid-scale(
      padding-top,
      functions.padding(small),
      functions.padding(x-small)
    );
    @include base.fluid-scale(
      padding-bottom,
      functions.padding(small),
      functions.padding(x-small)
    );
  }

  &__close {
    @include base.fluid-scale(
      margin-left,
      functions.padding(small),
      functions.padding(x-small)
    );

    flex: 0 0 auto;
  }

  &__close-svg {
    width: 24px;
    height: 24px;
  }
}
